.m-privacypolicy {
    .blue-gradient {
        color: #fff;
        background: #0092ff;
        background: @arco-blue-gradient;
    }
    .white-gradient {
        color: @arco-darkcopy;
        background: #ffffff;
        background: @arco-white-gradient;
    }
    #privacy-copy {
        padding-bottom: 50px;
    }
    a {
        text-decoration: underline;
    }
}
