	.m-promo-content {
    .blue-gradient {
        color: #fff;
        background: #0092ff;
        background: @arco-blue-gradient;
    }
    .white-gradient {
        color: @arco-darkcopy;
        background: #ffffff;
        background: @arco-white-gradient;
    }
    // #promo-content-copy {
    //     padding-bottom: 50px;
    // }
    a {
        text-decoration: underline;
    }
    @media (max-width: @screen-xs-max) {
        #promo-content-intro,
        #promo-content-copy {
            padding: 45px 0px;
            h3 {
                font-size: 30px;
            }
            p, ol li {
                font-size: 19px;
            }
        }
    }
    @media (min-width: @screen-sm) {
        #promo-content-intro,
        #promo-content-copy {
            padding-top: 50px;
            padding-bottom: 60px;
            h3 {
                font-size: 36px;
            }
            p, ol li {
                font-size: 22px;
            }
        }
    }
    @media (min-width: @screen-md) {
        #promo-content-introm #promo-content-copy {
            padding-top: 50px;
            padding-bottom: 60px;
            h3 {
                font-size: 40px;
            }
            p, ol li {
                font-size: 26px;
            }
        }
    }
    @media (min-width: @screen-lg) {
        #promo-content-intro,
        #promo-content-copy {
            padding-top: 65px;
            padding-bottom: 80px;
            h3 {
                font-size: 60px;
            }
            p, ol li {
                font-size: 32px;
            }
        }
    }
}
