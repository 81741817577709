.m-contactus {

    .btn[disabled] {
        opacity:0.65;
        background-color: #333333;
        border-color: #333333;
    }
    .form-group {
        min-height: 62px;
    }
    select,
    input {
        height: 44px;
    }
    input.form-control {
        padding-top: 20px;
    }
    .form-result {
        padding-bottom: 20px;
    }
    // Begin floating labels
    .floating-label-wrapper {
        position: relative;
    }
    .floating-label {
        color: #7E7E7E;
        font-size: 14px;
        position: absolute;
        pointer-events: none;
        left: 28px;
        top: 13px;
        transition: 0.2s ease all;
        opacity: 0;
    }
    .form-control:focus ~ .floating-label,
    .form-control:not(.empty) ~ .floating-label {
        top: 4px;
        left: 28px;
        font-size: 11px;
        opacity: 1;
    }
    .form-control.empty ~ .floating-label {
        opacity: 1;
    }
    textarea {
        resize: none;
    }
    textarea ~ .form-control-highlight {
        margin-top: -11px;
    }
    // End floating labels
    // input.ng-not-empty {
    //     padding-top: 16px;
    // }
    // .floating-label {
    //     padding-top: 0;
    //     label {
    //         display: none;
    //         z-index: 1;
    //         left: 13px;
    //         &.active {
    //             display: block;
    //         }
    //     }
    // }
    .underline-text {
        text-decoration: underline;
    }
    .blue-gradient {
        color: #fff;
        background: #0092ff;
        background: @arco-blue-gradient;
    }
    .white-gradient {
        color: @arco-darkcopy;
        background: #fff;
        background: @arco-white-gradient;
    }
    .pod {
        > h6 {
            font-family: @arco-type-bold;
        }
        > div.infoitem {
            text-decoration: underline;
        }
    }
    #contactus-form {
        .privacy {
            margin-top: 15px;
        }
    }
    .form-group .text-right {
        font-size: 12px;
        color: @brand-arco-red;
    }
    a:not(.btn) {
        color: inherit;
        text-decoration: underline;
        &:hover {
            color: @brand-arco-red;
        }
    }
    // Mobile first landscape (< 768px)
    @media(max-width: @screen-xs-max) {
        .btn {
            width: 144px;
        }
        h3 {
            font-size: 30px;
        }
        h6 {
            font-size: 19px;
        }
        p {
            font-size: 19px;
        }
        .infoitem {
            font-size: 19px;
        }
        #contactus-intro {
            padding-top: 50px;
            padding-bottom: 20px;
            p {
                // font-size: 19px;
                margin-bottom: 50px;
            }
            h3 {
                margin-bottom: 30px;
            }
            hr {
                width: 80%;
                margin-top: 0px;
                margin-bottom: 12px;
            }
            .iconitem {
                margin-bottom: 15px;
            }
            .pod {
                margin-bottom: 50px;
            }
            .pod1 {
                img {
                    height: auto;
                    position: relative;
                    right: 5px;
                }
            }
            .pod2 {
                img {
                    height: auto;
                }
            }
            .pod3 {
                img {
                    height: auto;
                }
            }
        }
        #contactus-form {
            padding-top: 50px;
            padding-bottom: 50px;
            textarea {
                height: 100px;
            }
            p {
                margin-bottom: 50px;
            }
            // label:not(.active) {
            //     font-weight: 500;
            //     font-size: 30px;
            // }
            .form-group-heading {
                font-size: 30px;
                font-weight: 500;
            }
            .container {
                > form > div:nth-child(4) {
                    // margin-bottom: 30px;
                }
            }
        }
    }
    // Mobile first portrait (< 480px)
    @media(max-width: (@screen-xs - 1)) {}
    // sm >= 768px 
    @media(min-width: @screen-sm) {
        .btn {
            width: 186px;
        }
        h3 {
            font-size: 36px;
        }
        // label {
        //     font-size: 24px;
        //     font-weight: 500;
        //     margin-bottom: 0;
        // }
        #contactus-intro {
            height: 375px;
            padding-top: 40px;
            hr {
                margin-top: 0px;
                margin-bottom: 8px;
            }
            p {
                padding: 0 38px;
                line-height: 17px;
            }
            .pods {
                padding-top: 66px;
                .pod1 {
                    padding-right: 0;
                    img {
                        height: 36px
                    }
                }
                .pod2 {
                    img {
                        position: relative;
                        top: 5px;
                        height: 30px
                    }
                }
                .pod3 {
                    padding-left: 0;
                    img {
                        height: 37px
                    }
                }
                .pod {
                    > h6 {
                        margin: 10px 0 5px 0;
                    }
                    > div.iconitem {
                        height: 33px;
                    }
                }
                .pod.col-sm-4 {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
            .text-center > div {
                font-size: 11px;
            }
        }
        #contactus-form {
            padding-top: 40px;
            padding-bottom: 50px;
            h3 {
                margin-bottom: 20px;
            }
            p {
                margin-bottom: 30px;
            }
            .form-group-heading {
                font-size: 24px;
                
            }
            .container {
                > form > div:nth-child(4) {
                    // margin-bottom: 30px;
                }
            }
        }
    }
    // md >= 992px
    @media(min-width: @screen-md) {
        h3 {
            font-size: 40px;
        }
        p {
            font-size: 18px;
        }
        // label {
        //     font-size: 30px;
        // }
        #contactus-intro {
            height: 454px;
            p {
                line-height: 21px;
            }
            h6 {
                font-size: 17px;
            }
            .pods {
                padding-top: 66px;
                .pod1 {
                    padding-right: 0;
                    img {
                        height: 40px
                    }
                }
                .pod2 {
                    img {
                        position: relative;
                        top: 5px;
                        height: 34px
                    }
                }
                .pod3 {
                    padding-left: 0;
                    img {
                        height: 39px
                    }
                }
                .pod {
                    > h6 {
                        margin: 10px 0 5px 0;
                    }
                    > div.iconitem {
                        height: 45px;
                    }
                }
                .pod.col-sm-4 {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
            .text-center > div {
                font-size: 14px;
            }
        }
        #contactus-form {
            .container {
                > form > div:nth-child(4) {
                    // margin-bottom: 40px;
                }
            }
        }
    }
    // lg >= 1200px
    @media(min-width: @screen-lg) {
        h3 {
            font-size: 60px;
            margin-bottom: 35px;
        }
        p {
            font-size: 24px;
        }
        // label {
        //     font-size: 36px;
        // }
        #contactus-intro {
            padding-top: 90px;
            height: 735px;
            p {
                line-height: 29px;
            }
            h6 {
                font-size: 21px;
            }
            hr {
                margin-bottom: 18px;
            }
            .pods {
                padding-top: 146px;
                .pod1 {
                    padding-right: 0;
                    img {
                        height: auto;
                    }
                }
                .pod2 {
                    img {
                        position: relative;
                        top: 5px;
                        height: auto;
                    }
                }
                .pod3 {
                    padding-left: 0;
                    img {
                        height: auto;
                    }
                }
                .pod {
                    > h6 {
                        margin: 10px 0 12px 0;
                    }
                    > div.iconitem {
                        height: 55px;
                    }
                }
                .pod.col-sm-4 {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
            .text-center > div {
                font-size: 17px;
            }
        }
        #contactus-form {
            .container {
                > form > div:nth-child(4) {
                    // margin-bottom: 50px;
                }
            }
        }
    }
}