// font-family:'DIN Next W01 Light';
// font-family:'DIN Next W01 Regular';
// font-family:'DIN Next W01 Medium';
// font-family:'DIN Next W01 Bold';
// font-family:'DIN Next W01 Black';
.m-mobilewallet {
    overflow: hidden;
    .blue-gradient {
        color: #fff;
        background: #0092ff;
        background: @arco-blue-gradient;
        h3 {
            font-size: 30px;
            margin-top: 11px;
        }
        h2 {
            font-size: 17px;
            margin-bottom: 7px;
        }
        p {
            font-size: 17px;
        }
    }
    .white-gradient {
        color: @arco-darkcopy;
        background: #ffffff;
        background: @arco-white-gradient;
        h3 {
            font-size: 30px;
            margin-top: 11px;
        }
        h2 {
            font-size: 17px;
            margin-bottom: 7px;
        }
        p {
            font-size: 17px;
        }
    }
    a:not(.btn) {
        color: inherit;
        text-decoration: underline;
        &:hover {
            color: @brand-arco-red;
        }
    }
    // Mobile
    @media (max-width: @screen-xs-max) {
        a.btn {
            width: 144px;
        }
        #wallet-intro {
            background: url('/img/mobilewallet-prepaid-bg-shade.png') no-repeat 50% 15%/100% auto, @arco-white-gradient;
            font-size: 16px;
            padding-top: 50px;
            padding-bottom: 80px;
            .phone-holder {
                padding: 0 25%;
            }
            h3 {
                margin-bottom: 18px;
            }
            p {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 24px;
            }
            .col-xs-3.text-xs-right {
                padding-right: 8px;
                font-family: @arco-type-bold;
            }
            .col-xs-7 {
                padding-left: 0;
            }
            .col-xs-12 {
                .row {
                    margin-top: 20px;
                }
            }
            .container {
                > .row {
                    > .col-xs-12 {
                        div:nth-child(10) {
                            margin-bottom: 30px;
                        }
                    }
                }
                .mobile-wallet-vendors {
                    > img {
                        height: 30px;
                        margin: 0 13px;
                    }
                }
            }
            #faq-info-div {
                margin-bottom: 30px;
            }
        }
        #prepaid-gas-card {
            background: url('/img/mobilewallet-prepaid-bg-shade.png') no-repeat 50% 0/100% auto, @arco-blue-gradient;
            font-size: 19px;
            padding-top: 50px;
            padding-bottom: 80px;
            h3 {
                margin-bottom: 18px;
            }
            p {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 24px;
            }
            .col-xs-12 {
                img {
                    position: relative;
                    top: 35px;
                    left: 15px;
                    //height: 215px;
                }
            }
        }
        #prepaid-gas-card.white-gradient {
            background: url('/img/mobilewallet-prepaid-bg-shade.png') no-repeat 50% 0/100% auto, @arco-white-gradient;
        }

        #arco-fleet-cards {
            background: url('/img/mobilewallet-fleet-cards-bg.png') no-repeat 50% 5px/100% auto, @arco-blue-gradient;
            font-size: 19px;
            padding-top: 30px;
            padding-bottom: 90px;
            h3 {
                margin-bottom: 18px;
            }
            p {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 24px;
                text-align: left;
            }
            img.white-ribbon {
             position: absolute;
             top: 65px;
             max-width: 95px;
             left: 15px;
            }
            .fleet-cta {
             clear: both;
             a {

             }
            }
            ul.visible-xs {
             margin-left: -20px;
             font-size: 16px;
             line-height: 21px;
            }
            .col-xs-12 {
                img.cards {
                    position: relative;
                    //top: 30px;
                    left: 0;
                    max-width: 330px;
                    margin-bottom: 40px;
                    //height: 245px;
                }

            }
        }
        #arco-fleet-cards.white-gradient{
            background: url('/img/mobilewallet-fleet-cards-white-bg.png') no-repeat 50% 5px/100% auto, @arco-white-gradient;
        }

        #debit-credit-card {
            background: url('/img/mobilewallet-debit-credit-cards-mobile-bg.png') no-repeat 50% 0/100% auto, @arco-blue-gradient;
            font-size: 19px;
            padding-top: 50px;
            padding-bottom: 80px;
            h3 {
                margin-bottom: 18px;
            }
            p {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 24px;
            }
            .col-xs-12 {
                img {
                    position: relative;
                    top: 35px;
                    //left: 15px;
                    //height: 215px;
                }
            }
        }
    }
    // Mobile portrait only
    @media (max-width: (@screen-xs - 1)) {
        #wallet-intro {
            // background: url('/img/mobilewallet-intro-bg.png') no-repeat 50% 0/100% auto, @arco-blue-gradient;
            background: url('/img/mobilewallet-prepaid-bg-shade.png') no-repeat 50% 0/100% auto, @arco-white-gradient;

            padding-top: 40px;
            padding-bottom: 70px;
        }
    }
    // Tablet (>=768px)
    @media (min-width: @screen-sm) {
        a.btn {
            width: 186px;
        }
        #wallet-intro {
            padding-top: 38px;
            padding-bottom: 47px;
            height: 376px;
            // background: url('/img/mobilewallet-intro-bg-shade.png') no-repeat 50% 0/@screen-md auto, #0092ff;
            // background: url('/img/mobilewallet-intro-bg-shade.png') no-repeat 50% 0/@screen-md auto, @arco-blue-gradient;

            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat 50% 0/@screen-md auto, #0092ff;
            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat 50% 0/@screen-md auto, @arco-white-gradient;

            .col-xs-3.text-xs-right {
                font-family: @arco-type-bold;
            }
            .col-xs-7 {
                padding-left: 0;
            }
            .col-xs-12 {
                .row {
                    margin-top: 6px;
                }
            }
            .container {
                > .row {
                    > .col-xs-12 {
                        div:nth-child(10) {
                            margin-bottom: 10px;
                        }
                    }
                }
                > .row {
                    div:nth-child(1) {
                        > img {
                            position: absolute;
                            top: 0px;
                            right: 24px;
                            height: 297px;
                        }
                    }
                }
                h3 {
                    margin-top: 23px;
                    font-size: 32px;
                    margin-bottom: 13px;
                }
                .col-xs-12 {
                    p {
                        font-size: 14px;
                    }
                    p:nth-of-type(3) {
                        margin-bottom: 10px;
                    }
                    p:nth-of-type(4) {
                        margin-bottom: 10px;
                    }
                    > .row {
                        font-size: 14px;
                        .col-sm-10 {
                            padding-left: 0;
                        }
                    }
                    > div:nth-last-of-type(2) {
                        margin-bottom: 10px;
                    }
                }
                .mobile-wallet-vendors {
                    > img {
                        height: 24px;
                    }
                    > img:nth-child(2) {
                        margin: 0 13px;
                    }
                }
            }
            #faq-info-div {
                margin-bottom: 10px;
            }
        }
        #prepaid-gas-card {
            padding-top: 40px;
            padding-bottom: 79px;
            
            // background: url('/img/mobilewallet-prepaid-bg-shade.png') no-repeat 50% 0/@screen-md auto, #0092ff;
            // background: url('/img/mobilewallet-prepaid-bg-shade.png') no-repeat 50% 0/@screen-md auto, @arco-white-gradient;

            background: url('/img/toptier-forless-bg-shade.png') no-repeat 50% 0/@screen-md auto, #fff;
            background: url('/img/toptier-forless-bg-shade.png') no-repeat 50% 0/@screen-md auto, @arco-blue-gradient;
            h3 {
                margin-top: 23px;
                font-size: 32px;
                margin-bottom: 13px;
            }
            p {
                font-size: 14px;
                margin-bottom: 10px;
            }
            p:nth-child(6) {
                margin-bottom: 25px;
            }
            img {
                position: absolute;
                top: 48px;
                left: 60px;
                height: 210px;
            }
            img.cards {
                position: absolute;
                top: 55px;
                left: 20px;
                height: 240px;
            }
        }
        #prepaid-gas-card.white-gradient {
            background: url('/img/mobilewallet-prepaid-bg-shade.png') no-repeat 50% 0/@screen-md auto, #fff;
            background: url('/img/mobilewallet-prepaid-bg-shade.png') no-repeat 50% 0/@screen-md auto, @arco-white-gradient;
        }

        #arco-fleet-cards {
            padding-top: 9px;
            padding-bottom: 90px;
            height: 376px;
            background: url('/img/mobilewallet-fleet-cards-bg-desktop.png') no-repeat 50% 0/@screen-md auto, #0092ff;
            background: url('/img/mobilewallet-fleet-cards-bg-desktop.png') no-repeat 50% 0/@screen-md auto, @arco-blue-gradient;
            h3 {
                margin-top: 23px;
                font-size: 32px;
                margin-bottom: 13px;
            }
            p {
                font-size: 13px;
                margin-bottom: 8px;
            }
            // p:nth-child(4) {
            //     margin-bottom: 25px;
            // }
            img.cards {
                position: absolute;
                top: 55px;
                left: 100px;
                height: 200px;
            }
            img.white-ribbon {
               position: absolute;
               max-width: 100px;
               top: 0;
               left: 75px;
               
            }
            ul {

              li {
                font-size: 13px;
                padding-bottom: 7px;

              }
            }
            a.btn {
              margin-top: 15px;
            }
            
        }

        #arco-fleet-cards.white-gradient{
            background: url('/img/mobilewallet-fleet-cards-white-bg.png') no-repeat 50% 0/@screen-md auto, #fff;
            background: url('/img/mobilewallet-fleet-cards-white-bg.png') no-repeat 50% 0/@screen-md auto, @arco-white-gradient;
        }

        #debit-credit-card {
            padding-top: 40px;
            padding-bottom: 85px;
            
            background: url('/img/mobilewallet-debit-credit-cards-bg.png') no-repeat 50% 0/@screen-lg auto, #fff;
            background: url('/img/mobilewallet-debit-credit-cards-bg.png') no-repeat 50% 0/@screen-lg auto, @arco-blue-gradient;

            h3 {
                margin-top: 70px;
                font-size: 32px;
                margin-bottom: 15px;
            }
            p {
                font-size: 14px;
                // line-height: 21px;
                margin-bottom: 10px;
            }
            img {
                position:absolute;
                top: 25px;
                left:40px;
                height: 230px;
            }
        }
    }
    // Medium (>=992px)
    @media (min-width: @screen-md) {
        #wallet-intro {
            height: 454px;
            padding-top: 40px;
            // padding-bottom: 85px;
            // background: url('/img/mobilewallet-intro-bg-shade.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
            // background: url('/img/mobilewallet-intro-bg-shade.png') no-repeat 50% 0/@screen-lg auto, @arco-blue-gradient;

            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat 50% 0/@screen-lg auto, #fff;
            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat 50% 0/@screen-lg auto, @arco-white-gradient;
            .col-xs-12 {
                .row {
                    margin-top: 10px;
                }
            }
            .container {
                > .row {
                    > .col-xs-12 {
                        div:nth-child(10) {
                            margin-bottom: 25px;
                        }
                    }
                }
                > .row {
                    div:nth-child(1) {
                        > img {
                            position: absolute;
                            top: 10px;
                            right: 63px;
                            height: 353px;
                        }
                    }
                }
                h3 {
                    margin-top: 30px;
                    font-size: 40px;
                    margin-bottom: 20px;
                }
                .col-xs-12 {
                    p {
                        font-size: 18px;
                        line-height: 21px;
                    }
                    p:nth-of-type(3) {
                        margin-bottom: 20px;
                    }
                    p:nth-of-type(4) {
                        margin-bottom: 20px;
                    }
                    > .row {
                        font-size: 18px;
                        line-height: 21px;
                        .col-sm-10 {
                            padding-left: 0;
                        }
                    }
                    > div:nth-last-of-type(2) {
                        margin-bottom: 10px;
                    }
                }
                .mobile-wallet-vendors {
                    > img {
                        height: 24px;
                    }
                    > img:nth-child(2) {
                        margin: 0 13px;
                    }
                }
            }
        }
        #prepaid-gas-card {
            height: 454px;
            padding-top: 50px;
            // padding-bottom: 115px;
            // background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat 50% 0/@screen-lg auto, #fff;
            // background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat 50% 0/@screen-lg auto, @arco-white-gradient;

            background: url('/img/toptier-forless-bg-shade.png') no-repeat center center, #0092ff;
            background: url('/img/toptier-forless-bg-shade.png') no-repeat center center, @arco-blue-gradient;
            h3 {
                margin-top: 30px;
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                font-size: 18px;
                // line-height: 21px;
                margin-bottom: 20px;
            }
            img {
                top: 45px;
                left: 10px;
                height: 250px;
            }
            img.cards {
                top: 90px;
                left: 130px;
                height: 240px;
            }
        }
        #prepaid-gas-card.white-gradient {
            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat 50% 0/@screen-lg auto, #fff;
            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat 50% 0/@screen-lg auto, @arco-white-gradient;
        }

        #arco-fleet-cards {
            height: 454px;
            padding-top: 0;
            // padding-bottom: 66px;
            background: url('/img/mobilewallet-fleet-cards-bg-desktop.png') no-repeat 75% 0/@screen-lg auto, #0092ff;
            background: url('/img/mobilewallet-fleet-cards-bg-desktop.png') no-repeat 75% 0/@screen-lg auto, @arco-blue-gradient;
            h3 {
                margin-top: 50px;
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                font-size: 16px;
                // line-height: 21px;
                margin-bottom: 15px;
            }
            ul {
              li {
                font-size: 16px;
              }
            }
            img.cards {
                top: 60px;
                left: 30px;
                height: 320px;
            }
            img.white-ribbon {
               position: absolute;
               max-width: 100px;
               top: 0;
               left: 70px;
            }
        }

        #arco-fleet-cards.white-gradient{
            background: url('/img/mobilewallet-fleet-cards-white-bg.png') no-repeat 75% 0/@screen-lg auto, #fff;
            background: url('/img/mobilewallet-fleet-cards-white-bg.png') no-repeat 75% 0/@screen-lg auto, @arco-white-gradient;
        }
        #debit-credit-card {
            height: 454px;
            padding-top: 50px;
            // padding-bottom: 115px;
            background: url('/img/mobilewallet-debit-credit-cards-bg.png') no-repeat 50% 0/@screen-lg auto, #fff;
            background: url('/img/mobilewallet-debit-credit-cards-bg.png') no-repeat 50% 0/@screen-lg auto, @arco-blue-gradient;

            h3 {
                margin-top: 80px;
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                font-size: 18px;
                // line-height: 21px;
                margin-bottom: 20px;
            }
            img {
                top: 25px;
                left: 10px;
                height: 250px;
            }
        }
    }
    // Large (>=1200px)
    @media(min-width: @screen-lg) {
        #wallet-intro {
            height: 735px;
            padding-top: 158px;
            padding-bottom: 156px;
            // background: url('/img/mobilewallet-intro-bg-shade.png') no-repeat center center, #0092ff;
            // background: url('/img/mobilewallet-intro-bg-shade.png') no-repeat center center, @arco-blue-gradient;

            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat center center, #fff;
            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat center center, @arco-white-gradient;

            .col-xs-12 {
                .row {
                    margin-top: 15px;
                }
            }
            .container {
                > .row {
                    > .col-xs-12 {
                        div:nth-child(10) {
                            margin-bottom: 25px;
                        }
                    }
                }
                > .row {
                    div:nth-child(1) {
                        > img {
                            position: absolute;
                            top: -28px;
                            right: 90px;
                            height: 491px;
                        }
                    }
                }
                h3 {
                    margin-top: 0px;
                    font-size: 53px;
                    margin-bottom: 30px;
                }
                .col-xs-12 {
                    p {
                        font-size: 23px;
                        line-height: 26px;
                    }
                    p:nth-of-type(3) {
                        margin-bottom: 30px;
                    }
                    p:nth-of-type(4) {
                        margin-bottom: 30px;
                    }
                    > .row {
                        font-size: 23px;
                        line-height: 26px;
                        .col-sm-10 {
                            padding-left: 0;
                        }
                    }
                    > div:nth-last-of-type(2) {
                        margin-bottom: 10px;
                    }
                }
                .mobile-wallet-vendors {
                    > img {
                        height: auto;
                    }
                    > img:nth-child(2) {
                        margin: 0 13px;
                    }
                }
            }
        }

        #prepaid-gas-card {
            height: 735px;
            padding-top: 158px;
            padding-bottom: 156px;
            // background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat center center, #fff;
            // background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat center center, @arco-white-gradient;

            background: url('/img/toptier-forless-bg-shade.png') no-repeat center center, #0092ff;
            background: url('/img/toptier-forless-bg-shade.png') no-repeat center center, @arco-blue-gradient;
            h3 {
                margin-top: 20px;
                font-size: 53px;
                margin-bottom: 30px;
            }
            p {
                font-size: 23px;
            }
            img {
                height: auto;
            }

            img.cards {
                top: 100px;
                left: 110px;
                height: 290px;
            }
        }

        #prepaid-gas-card.white-gradient {
            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat center center, #fff;
            background: url('/img/mobilewallet-prepaid-bg-shade-desktop.png') no-repeat center center, @arco-white-gradient;
        }

        #arco-fleet-cards {
            height: 715px;
            padding-top: 78px;
            padding-bottom: 125px;
            background: url('/img/mobilewallet-fleet-cards-bg-desktop.png') no-repeat center center, #0092ff;
            background: url('/img/mobilewallet-fleet-cards-bg-desktop.png') no-repeat center center, @arco-blue-gradient;
            h3 {
                margin-top: 10px;
                font-size: 53px;
                margin-bottom: 20px;
            }
            p {
                font-size: 22px;
            }

            ul {

              li {
                font-size: 22px;
              }
            }

            img.cards {
                top: 50px;
                left: 20px;
                height: 400px;
            }
            
            img.white-ribbon {
               position: relative;
               margin-top: -78px;
               max-width: 100px;
               //top: 0;
               left: 70px;
            }

            a.btn {
              margin-top: 25px;
            }
        }

        #arco-fleet-cards.white-gradient{
            background: url('/img/mobilewallet-fleet-cards-white-bg.png') no-repeat center center, #fff;
            background: url('/img/mobilewallet-fleet-cards-white-bg.png') no-repeat center center, @arco-white-gradient;
        }

       #debit-credit-card {
            height: 735px;
            padding-top: 158px;
            padding-bottom: 156px;
            background: url('/img/mobilewallet-debit-credit-cards-bg.png') no-repeat 70% 0/@screen-lg auto, #fff;
            background: url('/img/mobilewallet-debit-credit-cards-bg.png') no-repeat 70% 0/@screen-lg 100%, @arco-blue-gradient;


            h3 {
                margin-top: 70px;
                font-size: 53px;
                margin-bottom: 30px;
            }
            p {
                font-size: 23px;
            }
            img {
                height: auto;
            }
        }
    }
}
