.m-hellocreditcards {
    .blue-gradient {
        color: #fff;
        background: #0092ff;
        background: @arco-blue-gradient;
    }
    .white-gradient {
        color: @arco-darkcopy;
        background: #ffffff;
        background: @arco-white-gradient;
    }
    // #hellocreditcards-copy {
    //     padding-bottom: 50px;
    // }
    a {
        text-decoration: underline;
    }
    @media (max-width: @screen-xs-max) {
        #hellocredit-intro,
        #hellocreditcards-copy {
            padding: 45px 0px;
            h3 {
                font-size: 30px;
            }
            p {
                font-size: 19px;
            }
        }


    }
    @media (min-width: @screen-sm) {
        #hellocredit-intro,
        #hellocreditcards-copy {
            padding-top: 50px;
            padding-bottom: 60px;
            h3 {
                font-size: 36px;
            }
            p {
                font-size: 22px;
            }
        }
    }
    @media (min-width: @screen-md) {
        #hellocredit-introm #hellocreditcards-copy {
            padding-top: 50px;
            padding-bottom: 60px;
            h3 {
                font-size: 40px;
            }
            p {
                font-size: 26px;
            }
        }
    }
    @media (min-width: @screen-lg) {
        #hellocredit-intro,
        #hellocreditcards-copy {
            padding-top: 65px;
            padding-bottom: 80px;
            h3 {
                font-size: 60px;
            }
            p {
                font-size: 32px;
            }
        }
    }
}

.m-creditcards {
    .blue-gradient {
        color: #fff;
        background: #0092ff;
        background: @arco-blue-gradient;
    }
    .white-gradient {
        color: @arco-darkcopy;
        background: #ffffff;
        background: @arco-white-gradient;
    }
    // #hellocreditcards-copy {
    //     padding-bottom: 50px;
    // }
    a {
        text-decoration: underline;
    }

    .table-bordered {
        margin: 0px auto;
        float: none;
        border: 2px solid #343433;
    }

    .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
        border: 1px solid #343433;
    }

    .table-bordered>thead>tr>th {
        text-align:center;
        background-color:#2166b0;
        color:#fff;
        border-bottom-width:1px;
    }

    .table-bordered>tbody>tr>td {
        text-align:center;
        color:#343433;
        font-weight:bold;
    }

    .table-striped > tbody > tr:nth-child(2n+1) > td {
       background-color:#d2dbe3;
    }

    @media (max-width: @screen-xs-max) {
        #credit-intro {
            padding: 20px 0px;
            h2 {
                font-size:28px;
            }
        }
        #creditcards-copy {
            padding: 20px 0px;
            h2 {
                font-size:28px;
            }
            h3 {
                font-size: 24px;
                padding-bottom:40px;
            }
            p {
                font-size: 18px;
                padding-top:20px;
            }
        }


    }
    @media (min-width: @screen-sm) {
        .table-bordered {
            width:80%;
        }
        #credit-intro {
            padding-top: 65px;
            padding-bottom: 80px;
        }
        #creditcards-copy {
            padding-top: 40px;
            padding-bottom: 60px;
            h2 {
                font-size:28px;
            }
            h3 {
                font-size: 24px;
                padding-bottom: 40px;
            }
            p {
                font-size: 18px;
                padding-top:20px;
            }
        }
    }
    @media (min-width: @screen-md) {
        .table-bordered {
            width:70%;
        }
        #credit-intro {
            padding-top: 65px;
            padding-bottom: 80px;
        }
         #creditcards-copy {
            padding-top: 30px;
            padding-bottom: 80px;
            h2 {
                font-size:36px;
            }
            h3 {
                font-size: 32px;
                padding-bottom: 40px;
            }
            p {
                font-size: 26px;
                padding-top:20px;
            }
        }
    }
    @media (min-width: @screen-lg) {
        .table-bordered {
            width:60%;
        }
        #credit-intro {
            padding-top: 65px;
            padding-bottom: 80px;
        }
        #creditcards-copy {
            padding-top: 30px;
            padding-bottom: 80px;
            h2 {
                font-size:40px;
            }
            h3 {
                font-size: 32px;
                padding-bottom: 40px;
            }
            p {
                font-size: 24px;
                padding-top:20px;
            }
        }
    }
}