// font-family:'DINNextLTW01-UltraLight_706122';
// font-family:'DIN Next W01 Light';
// font-family:'DIN Next W01 Regular';
// font-family:'DIN Next W01 Medium';
// font-family:'DIN Next W01 Bold';
// font-family:'DIN Next W01 Black';
@faq-red:     #ED1B2E;

.m-faq {
	.blue-gradient {
		color: #fff;
    background: #0092ff;
    background: @arco-blue-gradient;
    h3 {
    	font-family: @arco-type-thin;
        font-size: 30px;
        margin-top: 11px;
        margin-bottom: 25px;
        opacity: 1;
    }
    p {
        font-size: 19px;
        line-height: 1.25;
        opacity: 0.9;
    }
	}
	.white-gradient {
		color: @arco-darkcopy;
    background: #ffffff;
    background: @arco-white-gradient;

    h3 {
    		font-family: @arco-type-light;
        font-size: 30px;
        margin-top: 30px;
        margin-bottom: 15px;
        opacity: 0.9;
    }
    h2 {
        font-size: 17px;
        margin-bottom: 7px;
    }

    .faq-question-block {
    	margin: 10px 0px;

        .faq-red {
            color: @faq-red;
            cursor: pointer;
            float: left;
        }

        .expansion-icon {

            position: relative;
            display: inline-block;
            line-height: 2em;
            width: 2em;
            
        }



    }

    .faq-question-block-inner {
    	
    	width: 85%;
    	float: left;
    	margin-top: 5px;



    }
    p {
        font-size: 19px;
        display: inline-block;
        line-height: 1.2;

        &.question {
        	font-family: @arco-type-thin;
        }

        &.answer {
        	width: inherit;
            opacity: 1;

            &.ng-hide-add, &.ng-hide-remove {
                transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.25s;
                
            }

            &.ng-hide-add-active, &.ng-hide-add {
                
            }

        	&.ng-hide {
        		opacity: 0;
                line-height: 0;
        	}
        }
    }

	}
	




	@media (max-width: @screen-xs-max) {
    #faq-intro {
    	padding: 45px 0px;

    }

    #faq-body {

    	padding: 35px 0px;

    }

    .faq-question-block  {
    	p {
    		margin-top: 3px;
    	}
    }

    .faq-red {
    	top:1px;
        left: 15px;
    }

    .expansion-icon {
        font-size: 1.33em;
    }

    .faq-subsection {
    	
    }
    .outer-icon {
    	font-size: 1.05em;
    	opacity: .55;
    }
    .inner-icon {
    	font-size: .55em;
    	padding-right: 1px;
    	opacity: .95;
    	
    }

	}

    a {
        color: #0D65AF;
    }

	@media (min-width: @screen-sm) {
    
    #faq-intro, #faq-body {
    	padding-top: 50px;
        padding-bottom: 60px;
        p {
            font-size: 18px;
            opacity: 1;
        }
        h3 {
            font-size: 36px;
            margin-top: 25px;
            margin-bottom: 20px;
        }
        p.question {
            font-size: 22px;
            line-height: 1.15;
            margin-top: 0;
        }
        p.answer {
            width: initial;
            font-size: 18px;
            
            &.ng-hide-add, &.ng-hide-remove {
                transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.25s;
                
            }

            &.ng-hide-add-active, &.ng-hide-add {
                
            }

            &.ng-hide {
                opacity: 0;
                line-height: 0;
            }
        }
        .faq-red {
            
            left: 15px; 

        }

        .expansion-icon {
            font-size: 1.4em;
        }

    }

    .faq-question-block  {
    	p {
    		margin-top: 3px;
    	}
    }

    .faq-red {
    	bottom: 2px;
    	left: 5px;
    }

    .faq-subsection {
    	
    }
    
	}


	@media (min-width: @screen-md) {
    
    #faq-intro, #faq-body {
        padding-top: 50px;
        padding-bottom: 60px;
        
        p {
            font-size: 20px;
            opacity: 1;
        }
        h3 {
            font-size: 40px;
            margin-top: 25px;
            margin-bottom: 20px;
        }
        p.question {
            font-size: 26px;
            line-height: 1.15;
            margin-top: 0;
        }
        p.answer {
            width: inherit;
            font-size: 20px;
            padding-bottom: 15px;
        }
        .faq-red {
            
            left: 15px;   

        }

        .expansion-icon {
            font-size: 1.6em;
        }
    }
    #faq-body {
       // padding-left: 10%; 
    }

    .faq-question-block  {
        p {
            margin-top: 3px;
        }
    }

    
    .faq-subsection {
        
    }
    
    }



    

    @media (min-width: @screen-lg) {
    
    #faq-intro, #faq-body {
        padding-top: 65px;
        padding-bottom: 80px;
        
        p {
            font-size: 26px;
            opacity: 1;
        }
        h3 {
            font-size: 60px;
            margin-top: 25px;
            margin-bottom: 20px;
            
        }
        p.question {
            font-size: 32px;
            line-height: 1.15;
            margin-top: 0;
        }
        p.answer {
            
            font-size: 26px;
        }
        .faq-red {
            
            left: 15px;   

        }

        .expansion-icon {
            font-size: 1.95em;
        }


    }
    #faq-body {
        // padding-left: 10%;
    }

        .faq-question-block  {
            p {
                margin-top: 3px;
            }
        }

        

    }


    


}