/* ANIMATIONS
============================================================================= */


/* leaving animations ----------------------------------------- */


/* rotate and fall */

@keyframes rotateFall {
    0% {
        transform: rotateZ(0deg);
    }
    20% {
        transform: rotateZ(10deg);
        animation-timing-function: ease-out;
    }
    40% {
        transform: rotateZ(17deg);
    }
    60% {
        transform: rotateZ(16deg);
    }
    100% {
        transform: translateY(100%) rotateZ(17deg);
    }
}


/* slide in from the bottom */

@keyframes slideOutLeft {
    to {
        transform: translateX(-100%);
    }
}


/* rotate out newspaper */

@keyframes rotateOutNewspaper {
    to {
        transform: translateZ(-3000px) rotateZ(360deg);
        opacity: 0;
    }
}


/* entering animations --------------------------------------- */


/* scale up */

@keyframes scaleUp {
    from {
        opacity: 0.3;
        -webkit-transform: scale(0.8);
    }
}


/* slide in from the right */

@keyframes slideInRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}


/* slide in from the bottom */

@keyframes slideInUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}


/*
.ng-enter       { z-index: 8888; }
.ng-leave       { z-index: 9999; }
.content-begin.ng-enter         { animation: scaleUp 0.5s both ease-in; }
.content-begin.ng-leave         { transform-origin: 0% 0%; animation: rotateFall 1s both ease-in; }
.page-about.ng-enter        { animation:slideInRight 0.5s both ease-in; }
.page-about.ng-leave        { animation:slideOutLeft 0.5s both ease-in; }
.page-contact.ng-leave      { transform-origin: 50% 50%; animation: rotateOutNewspaper .5s both ease-in; }
.page-contact.ng-enter      { animation:slideInUp 0.5s both ease-in; }*/


/* start 'enter' transition on main view */

.content-begin.ng-enter {
    /* transition on enter for .5s */
    transition: .5s;
    /* start with opacity 0 (invisible) */
    opacity: 0;
}


/* end 'enter' transition on main view */

.content-begin.ng-enter-active {
    /* end with opacity 1 (fade in) */
    opacity: 1;
}

.ng-hide-add,
.ng-hide-remove {
    /* ensure visibility during the transition */
    display: block !important;
    /* yes, important */
}

.cssFade {
    transition: 0.2s linear all;
    opacity: 1;
}

.cssFade.ng-hide {
    opacity: 0;
}


/* when hiding the picture */


/*.ng-hide-add         { animation:0.5s lightSpeedOut ease; }*/


/* when showing the picture */


/*.ng-hide-remove      { animation:0.5s flipInX ease; }*/


/* ANIMATIONS (FROM ANIMATE.CSS) ======================== */


/* flip in */

@keyframes flipInX {
    0% {
        transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
        transition-timing-function: ease-in;
        opacity: 0;
    }
    40% {
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
        transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }
    60% {
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
        opacity: 1;
    }
    80% {
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    100% {
        transform: perspective(400px);
        transform: perspective(400px);
        transform: perspective(400px);
    }
}


/* light speed out */

@keyframes lightSpeedOut {
    0% {
        opacity: 1;
    }
    100% {
        transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0;
    }
}

@keyframes lightSpeedOut {
    0% {
        opacity: 1;
    }
    100% {
        transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        transform: translate3d(100%, 0, 0) skewX(30deg);
        opacity: 0;
    }
}