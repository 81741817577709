// findstation.less
.m-findstation {
    #map_canvas .store {
        .directions-link {
            display: none;
        }
    }
    // Panel contains a long list of stores, so always make sure you can overflow scroll
    #panel {
        overflow-x: hidden;
        overflow-y: scroll;
    }
    ul.features,
    ul.store-list {
        list-style-type: none;
        padding: 0;
        .store {
            padding-left: 20px;
            // min-height: 120px;
        }
        li.store {
            padding-top: 4px;
        }
        div.store {
            div.address {
                font-size: 12px;
                padding-right: 54px;
            }
            div.phone {
                font-size: 16px;
            }
            .directions-link {
                font-size: 14px;
            }
        }
    }
    .input-group-btn .btn {
        box-shadow: none;
        // font-size: 17px;
    }
    .blue-gradient {
        color: #fff;
        background: #0092ff;
        background: @arco-blue-gradient;
        h3 {
            font-size: 30px;
            margin-top: 11px;
        }
        h2 {
            font-size: 17px;
            margin-bottom: 7px;
        }
        p {
            font-size: 17px;
        }
    }
    .white-gradient {
        color: @arco-darkcopy;
        background: #ffffff;
        background: @arco-white-gradient;
        h3 {
            font-size: 30px;
            margin-top: 11px;
        }
        h2 {
            font-size: 17px;
            margin-bottom: 7px;
        }
        p {
            font-size: 17px;
        }
    }
    // BEGIN GLOBAL STATION FINDER STYLES 
    .location-search {
        padding-top: 20px;
    }
    .location-search .results {
        font-size: 12px;
        color: #6f6f6f;
        margin: 8px 0 0 0;
        letter-spacing: 1px;
        font-weight: bold;
    }
    .check-clear {
        display: inline-block;
        vertical-align: top;
        margin-left: 5px;
        margin-bottom: 0px;
    }
    li.store {
        position: relative;
        background: url('/img/findstation/blank-tag.png') no-repeat 4px 2px/auto auto;
    }
    li.store.highlighted {
        background-color: #dedede;
    }
    li.store .address,
    li.store .phone,
    li.store .directions-link {
        font-size: 14px;
        background: transparent !important;
    }
    li.store .features {
        list-style: none;
        padding: 0px;
    }
    .store-list li.store .title {
        display: none;
    }
    li.store .features li {
        display: inline-block;
        font-style: italic;
        padding: 0 4px 0 0;
        font-size: 12px;
    }
    li.store a {
        color: #025fa6;
        font-weight: bold;
    }
    li.store .features {
        color: #707070;
    }
    .gm-style .ampm,
    .gm-style .atm,
    .gm-style .index,
    .gm-style .distance {
        color: #000;
        display: none;
    }
    li.store:nth-child(odd) {
        // background-color: #eaecee;
    }
    li.store:nth-child(even) {
        // background-color: #f2f2f5;
    }
    li.store .distance {
        position: absolute;
        top: 0px;
        right: 15px;
        color: #025fa6;
        font-weight: bold;
        font-size: 14px;
    }
    .gm-style div {
        font-size: 13px !important;
    }
    .gm-style .title {
        font-size: 15px !important;
    }
    li.store .ampm:after,
    .gm-style .ampm:after {
        content: ", ";
    }
    li.store .index {
        position: absolute;
        color: #fff;
        font-size: 16px;
        top: 7px;
        left: 12px;
        font-weight: bold;
        line-height: 20px;
    }
    li.store .index.dd {
        font-size: 14px;
        left: 8px;
    }
    .gm-style-iw .store {
        min-width: 200px;
        padding-left: 19px;
        padding-top: 12px;
    }
    .gm-style-iw .store a {
        // display: inline-block;
        color: #025fa6;
        font-size: 14px;
    }
    .gm-style-iw .store > a {
        display: inline-block;
        color: #025fa6;
        font-size: 11px;
    }
    .gm-style-iw .store > a.zoomhere {
        padding: 0 34px;
    }
    .gm-style-iw .store .features {
        border-bottom: 1px solid #e8e8e8;
        margin: 10px 0 0 0;
        padding-bottom: 10px;
    }
    .gm-style-iw .directions-link {
        display: none !important;
    }
    .storelocator-filter {
        padding: 0px;
    }
    .storelocator-panel h4,
    .page-participating-stores .storelocator-panel h4 {
        padding-top: 0;
    }
    .feature-filter {
        color: #000;
        // border-top: 2px solid #005da4;
        // margin-top: 10px;
        overflow: hidden;
        // -webkit-transition: height 0.5s;
        // transition: height 0.5s;
        height: auto;
    }
    .feature-filter.closed {
        height: 44px;
    }
    .feature-filter h4 {
        cursor: pointer;
        background: url('/img/findstation/accordion-arrow-sprite.png') no-repeat 96% 6px;
    }
    .feature-filter.closed h4 {
        background-position: 96% -14px;
    }
    .location-search,
    .feature-filter {
        // padding-left: 10px;
    }
    .feature-filter input[type="checkbox"] {
        display: none;
    }
    .feature-filter input[type="checkbox"] + label {
        color: #5f6062;
        font-style: italic;
        text-decoration: none;
        font-weight: bold;
        font-size: 15px;
        display: inline-block;
        background: url('/img/findstation/feature-toggle-sprite.png') no-repeat 0 100%;
        padding-left: 55px;
        padding-right: 40px;
        line-height: 26px;
        margin: 10px 0;
        vertical-align: top;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .feature-filter input[type="checkbox"]:checked + label {
        background-position: 0 0;
    }
    // END GLOBAL STYLES
    // Mobile
    @media (max-width: @screen-xs-max) {
        .storelocator-panel {
            h4 {
                margin-top: 0;
            }
        }
        .feature-filter.closed {
            height: 35px;
        }
        .gm-style {
            .title,
            div {
                font-size: 11px !important;
            }
        }
        .gm-style-iw {
            .store {
                font-size: 11px !important;
                .features {
                    margin: 0;
                    padding-bottom: 0;
                }
                .phone {
                    a {
                        font-size: 10px !important;
                    }
                }
                > a {
                    font-size: 9px !important;
                }
            }
        }
        .leftcol {

            hr {
                margin-top: 9px;
                margin-bottom: 9px;
                margin-left: 0;
                margin-right: 0;
                border-top: 1px solid #ccc;
            }
            ul.store-list {
                li.store {
                    .distance {
                        right: 0px;
                    }
                    div.store {
                        div.phone {
                            font-size: 17px;
                        }
                        a {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
        .rightcol {
            box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.8);
            margin: 0 -4px;
            z-index: 20;
            padding-right: 0px;
            padding-left: 0px;
        }
        #panel {
            // height: 350px;
            overflow: scroll;
            // height: calc(100% - 50% - 50px);
            .location-search {
                padding-top: 10px;
            }
            .check-clear {
                display: none;
             
            }
            .feature-filter {
                padding-top: 10px;
            }
            h4#show-stations-heading {
                font-size: 15px;
            }
            .feature-filter input[type="checkbox"] + label {
                
                margin-top: 0px;
                
            }
            div.address {
                font-size: 16px;
                
            }
        }
        #map_canvas {
            // height: 400px;
            // height: calc(100% - 50% - 50px);
        }
        .map_container::after {
            content: "";
        }
        .map-module {
            position: relative;
        }
    }
    // Mobile portrait only
    @media (max-width: (@screen-xs - 1)) {
        // Nothing needed yet
    }
    // Tablet
    @media (min-width: @screen-sm) {
        > .container-fluid {
            // padding: 0;
        }
        .leftcol {
            // padding-right: 0;
            padding-right: 0px;
            hr {
                margin-top: 9px;
                margin-bottom: 9px;
                margin-left: 0;
                margin-right: 15px;
                border-top: 1px solid #ccc;
            }
        }
        .rightcol {
            padding-left: 0px;
            padding-right: 0px;
        }
        .input-group {
            margin-right: 10px;
        }
        #header_panel {
            top: 80px !important;
            bottom: none;
        }
    }
    // Medium
    @media (min-width: @screen-md) {
        #header_panel {
            top: 100px !important;
            bottom: none;
        }
    }
    // Large
    @media(min-width: @screen-lg) {
        #header_panel {
            top: 100px !important;
            bottom: none;
        }
    }
}