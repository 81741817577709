// font-family:'DIN Next W01 Light';
// font-family:'DIN Next W01 Regular';
// font-family:'DIN Next W01 Medium';
// font-family:'DIN Next W01 Bold';
// font-family:'DIN Next W01 Black';
.m-toptier {
    .underline-text {
        text-decoration: underline;
    }
    .blue-gradient {
        hr {
            border-top: 1px solid #fff;
            margin: 0 auto;
            padding: 0;
            width: 77%;
        }
        color: #fff;
        background: #0092ff;
        background: linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        h3 {
            font-size: 24px;
            margin-top: 11px;
        }
        h2 {
            font-size: 17px;
            margin-bottom: 7px;
        }
        p {
            font-size: 17px;
        }
    }
    .white-gradient {
        hr {
            border-top: 1px solid @arco-darkcopy;
            margin: 0 auto;
            padding: 0;
            width: 77%;
        }
        h3 {
            font-size: 24px;
            margin-top: 11px;
        }
        h2 {
            font-size: 17px;
            margin-bottom: 7px;
        }
        p {
            font-size: 17px;
        }
        color: @arco-darkcopy;
        background: #ffffff;
        background: linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
    }
    a:not(.btn) {
        color: inherit;
        text-decoration: underline;
        &:hover {
            color: @brand-arco-red;
        }
    }
}

@media(max-width: @screen-xs-max) {
    // Begin mobile large
    #whatis {
        //background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/750px auto;
        //background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/750px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        
        // reverse to white gradients
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/750px auto;
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/750px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        padding-top: 70px;
        padding-bottom: 80px;
        p {
            margin-bottom: 24px;
        }
        img.img-responsive.center-block {
            width: 50%;
        }
    }
    
    #whatis.blue-gradient {
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/750px auto, #0092ff;
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/750px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        background: linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }
    
    // #1
    #fuel-efficiency {
        background: url('../../../../img/toptier-1.png') no-repeat 45% 8%/95px auto;
        background: url('../../../../img/toptier-1.png') no-repeat 45% 8%/95px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // reverse to blue gradients
        background: url('../../../../img/toptier-1.png') no-repeat 45% 8%/95px auto;
        background: url('../../../../img/toptier-1.png') no-repeat 45% 8%/95px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 30px;
        padding-bottom: 50px;
        p {
            margin-bottom: 50px;
        }
        img.img-responsive.center-block {
            width: 100%;
        }
    }
    
    // #2
    #engine-performance {
        

       // blue gradients
       background: url('../../../../img/toptier-2.png') no-repeat 49% 8%/140px auto;
       background: url('../../../../img/toptier-2.png') no-repeat 49% 8%/140px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        // white gradients
        background: url('../../../../img/toptier-2.png') no-repeat 49% 8%/140px auto;
        background: url('../../../../img/toptier-2.png') no-repeat 49% 8%/140px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        
        

        

        

        padding-top: 30px;
        padding-bottom: 50px;
        p {
            margin-bottom: 24px;
        }
    }
    
    // #3
    #engine-protection {
        background: url('../../../../img/toptier-3.png') no-repeat 50% 8%/134px auto;
        background: url('../../../../img/toptier-3.png') no-repeat 50% 8%/134px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        // reverse to white gradients
        // background: url('../../../../img/toptier-2.png') no-repeat 50% 8%/134px auto, url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/750px auto;
        // background: url('../../../../img/toptier-2.png') no-repeat 50% 8%/134px auto, url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/750px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        padding-top: 30px;
        padding-bottom: 50px;
        p {
            margin-bottom: 24px;
        }
        img.img-responsive.center-block {
            width: 60%;
        }
    }
    
    //NW #1
   #engine-protection.white-gradient {
        background: #fff;
        background: url('../../../../img/toptier-1-white.png') no-repeat 45% 8%/95px auto;
        background: url('../../../../img/toptier-1-white.png') no-repeat 45% 8%/95px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
    }


    // #4
    #engine-cleanliness {
        background: url('../../../../img/toptier-4.png') no-repeat 50% 8%/145px auto;
        background: url('../../../../img/toptier-4.png') no-repeat 50% 8%/145px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // reverse to blue gradients
        // background: url('../../../../img/toptier-1.png') no-repeat 45% 8%/95px auto;
        // background: url('../../../../img/toptier-1.png') no-repeat 45% 8%/95px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 30px;
        padding-bottom: 50px;
        p {
            margin-bottom: 24px;
        }
        img.img-responsive.center-block {
            width: 80%;
        }
    }

    //#2 NW
    #engine-cleanliness.blue-gradient {
        background: url('../../../../img/toptier-2-blue.png') no-repeat 45% 8%/95px auto;
        background: url('../../../../img/toptier-2-blue.png') no-repeat 45% 8%/95px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }


    // #5
    #lower-emissions {
        // background: url('../../../../img/toptier-5.png') no-repeat 49% 8%/140px auto;
        // background: url('../../../../img/toptier-5.png') no-repeat 49% 8%/140px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // reverse to blue gradients
        background: url('../../../../img/toptier-5.png') no-repeat 49% 8%/140px auto;
        background: url('../../../../img/toptier-5.png') no-repeat 49% 8%/140px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 30px;
        padding-bottom: 50px;
        p {
            margin-bottom: 24px;
        }
    }

    #for-less {
        padding-top: 30px;
        padding-bottom: 50px;
        p {
            margin-bottom: 24px;
        }
        .toptier-fineprint {
            font-size: 11px;
            padding-top: 22px;
        }
    }
}

@media(max-width: (@screen-xs - 1)) {
    // Begin mobile small
    #whatis {
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto;
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto;
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        padding-top: 70px;
        padding-bottom: 70px;
        img.img-responsive.center-block {
            width: 70%;
        }
    }

    #whatis.blue-gradient {
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto, #0092ff;
        background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        background: linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }

    #engine-cleanliness {
        img.img-responsive.center-block {
            width: 100%
        }
    }

    #engine-cleanliness.blue-gradient {
        //background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto, #0092ff;
        //background: url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        //background: linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }

    #engine-protection {
        // background: url('../../../../img/toptier-2.png') no-repeat 50% 8%/134px auto, url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto;
        // background: url('../../../../img/toptier-2.png') no-repeat 50% 8%/134px auto, url('../../../../img/toptier-whatis-bg.png') no-repeat 50% 0/480px auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
    }



}

@media(min-width: @screen-sm) {
    // Begin tablet
    #whatis {
        //background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-md auto, url('../../../../img/toptier-whatis-bg-line.png') no-repeat 50% 0/@screen-md auto, #0092ff; // in IE9, the middle guy is gone
        //background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-md auto, url('../../../../img/toptier-whatis-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        // override to white
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-md auto, #ffffff; // in IE9, the middle guy is gone
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        h3 {
            margin-top: 67px;
            margin-bottom: 20px;
            font-size: 31px;
        }
        p {
            font-size: 15px;
        }
        img {
            position: relative;
            top: 24px;
            right: 27px;
        }
        padding-top: 73px;
        padding-bottom: 91px;
        .col-sm-5 {
            padding-left: 7%;
            padding-right: 5%;
        }
    }

    #whatis.blue-gradient {
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-md auto, url('../../../../img/toptier-whatis-bg-line.png') no-repeat 50% 0/@screen-md auto, #0092ff; // in IE9, the middle guy is gone
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-md auto, url('../../../../img/toptier-whatis-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }
    
    #fuel-efficiency {
        background: #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrides
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 80px;
        padding-bottom: 101px;
        .col-sm-6 {
            padding: 0;
        }
        .col-xs-12 {
            > img {
                position: absolute;
                // height: 230px;
                top: 50px;
                left: 15px;
                //height: 230px;
            }
        }
        .number-1 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 222px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 31px;
        }
        p {
            font-size: 15px;
        }
    }

    #engine-cleanliness {
        background: #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-md auto, #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrides
        // background: #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, #fff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 80px;
        padding-bottom: 80px;
        .col-sm-6 {
            padding: 0;
        }
        .col-xs-12 {
            > img {
                top: 30px;
                left: 55px;
                height: 225px;
                position: absolute;
            }
        }
        .number-4 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 222px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 31px;
        }
        p {
            font-size: 15px;
        }
    }

    #engine-cleanliness.blue-gradient {
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }

    #engine-protection {
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        // white overrides
        // background: #fff;
        // background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-md auto, #0092ff;
        // background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        padding-top: 80px;
        padding-bottom: 100px;
        .col-xs-12 {
            > img {
                position: absolute;
                top: -10px;
                height: 220px;
                left: 100px;
            }
        }
        .number-3 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 222px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 31px;
        }
        p {
            font-size: 15px;
        }
    }

    #engine-protection.white-gradient {
        background: #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-md auto, #0092ff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
    }

    #engine-performance {
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-md auto, #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrides
        // background: #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 88px;
        padding-bottom: 57px;
        .number-2 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 237px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 31px;
        }
        p {
            font-size: 15px;
        }
        .col-xs-12 {
            > img {
                
                position: absolute;
                top: 30px;
                left: 65px;
                height: 250px;
                
            }
        } 
    }
    #lower-emissions {
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrides
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 88px;
        padding-bottom: 57px;
        .number-5 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 237px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 31px;
        }
        p {
            font-size: 15px;
        }
        .col-xs-12 {
            > img {
                
                position: absolute;
                top: -15px;
                left: 20px;
                
            }
        }
    }
    #for-less {
        height: 375px;
        background: url('../../../../img/toptier-forless-bg-shade.png') no-repeat 50% 0/@screen-md auto, #0092ff;
        background: url('../../../../img/toptier-forless-bg-shade.png') no-repeat 50% 0/@screen-md auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        padding-top: 60px;
        padding-bottom: 105px;

        //start hack
        //Had to add the below bit to mimic col-offset-1
        //which was removed from the markup to make room
        //for bigger piggybank/hula asset
        h3 {
            margin-left: 14.33%;
        }
        
        p {
            margin-left: 16%;
        }
        //end hack

        hr {
            width: 100%
        }
        h3 {
            font-size: 31px;
            width: 100%;
            position: absolute;
        }
        p {
            font-size: 15px;
            padding-top: 60px;
            padding-right: 60px;
        }
        .toptier-fineprint {
            font-size: 10px;
            margin-top: 0px;
            padding-top: 7px;
            padding-right: 90px;
        }
        .col-xs-12 {
            > img {
                height: 250px;
                position: absolute;
                top: 5px;
                left: -15px;
            }
        } 
    }
}

@media(min-width: @screen-md) {
    // Begin Medium Desktop
    #whatis {
        //background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-lg auto, url('../../../../img/toptier-whatis-bg-line.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        //background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-lg auto, url('../../../../img/toptier-whatis-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        // override to white
        background: #fff;
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-lg auto, #ffffff;
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        padding-top: 75px;
        padding-bottom: 106px;
        h3 {
            margin-top: 54px;
            margin-bottom: 25px;
            font-size: 45px;
        }
        p {
            font-size: 21px;
        }
        img {
         top: 65px;
        }
    }

    #whatis.blue-gradient {
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-lg auto, url('../../../../img/toptier-whatis-bg-line.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat 50% 0/@screen-lg auto, url('../../../../img/toptier-whatis-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }

    #fuel-efficiency {
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrides
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 100px;
        padding-bottom: 108px;
        .col-xs-12 {
            > img {
                position: absolute;
                top: 50px;
                
            }
        }
        .number-1 {
            position: absolute;
            top: -12px;
            left: 26px;
            > img {
                height: 270px;
            }
        }
        h3 {
            // margin-top: 54px;
            // margin-bottom: 25px;
            font-size: 45px;
        }
        p {
            font-size: 21px;
        }
    }

    #engine-cleanliness {
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-lg auto, #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrides
        // background: #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 100px;
        padding-bottom: 108px;
        .col-xs-12 {
            > img {
                position: absolute;
                top: 35px;
                left: 68px;
                height: 280px;
            }
        }
        .number-4 {
            position: absolute;
            top: -12px;
            left: 26px;
            > img {
                height: 270px;
            }
        }
        h3 {
            // margin-top: 54px;
            // margin-bottom: 25px;
            font-size: 45px;
        }
        p {
            font-size: 21px;
        }
    }

    #engine-cleanliness.blue-gradient {
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }
    #engine-protection {
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        // white overrides
        // background: #fff;
        // background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-lg auto, #fff;
        // background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        padding-top: 100px;
        padding-bottom: 108px;
        .col-xs-12 {
            > img {
                top: -31px;
                height: 300px;
                left: 117px;
                position: absolute;
            }
        }
        .number-3 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 270px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 45px;
        }
        p {
            font-size: 21px;
        }
    }

    #engine-protection.white-gradient {
        background: #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-lg auto, #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
     }

    #engine-performance {
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-lg auto, #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrdies
        // background: #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 100px;
        padding-bottom: 51px;
        .number-3 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 307px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 45px;
        }
        p {
            font-size: 19px;
        }
    }
    #lower-emissions {
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrdies
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 100px;
        padding-bottom: 51px;
        .number-5 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 307px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 45px;
        }
        p {
            font-size: 19px;
        }
    }
    #for-less {
        height: 454px;
        background: url('../../../../img/toptier-forless-bg-shade.png') no-repeat 50% 0/@screen-lg auto, #0092ff;
        background: url('../../../../img/toptier-forless-bg-shade.png') no-repeat 50% 0/@screen-lg auto, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        padding-top: 75px;
        padding-bottom: 112px;
        
        //start hack
        //Had to add the below bit to mimic col-offset-1
        //which was removed from the markup to make room
        //for bigger piggybank/hula asset
        h3 {
            margin-left: 14.33%;
        }
        
        p {
            margin-left: 16%;
        }
        //end hack

        h3 {
            font-size: 45px;
            
            width: 100%;
            position: absolute;
        }
        p {
            font-size: 20px;
            padding-top: 85px;
            padding-right: 80px;
            
        }
        .toptier-fineprint {
            padding-top: 10px;
            padding-right: 150px;
            font-size: 12px;
            margin-top: 0px;
        }
        .col-xs-12 {
            > img {
                height: 290px;
                position: absolute;
                top: 10px;
                left: 0px;
            }
        }
    }
}

@media(min-width: @screen-lg) {
    // Begin Laree Desktop
    #whatis {
        //background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat center center, url('../../../../img/toptier-whatis-bg-line.png') no-repeat center center, #0092ff;
        //background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat center center, url('../../../../img/toptier-whatis-bg-line.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        // override to white 
        background: #fff;
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat center center, #ffffff;
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat center center, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        padding-top: 141px;
        padding-bottom: 135px;
        
        h3 {
            margin-top: 127px;
            margin-bottom: 21px;
            font-size: 52px;
        }
        p {
            font-size: 26px;
            line-height: 32px;
            margin: 0px;
        }
    }

    #whatis.blue-gradient {
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat center center, url('../../../../img/toptier-whatis-bg-line.png') no-repeat center center, #0092ff;
        background: url('../../../../img/toptier-whatis-bg-shade.png') no-repeat center center, url('../../../../img/toptier-whatis-bg-line.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }
    
    #fuel-efficiency {
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // Blues
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 185px;
        padding-bottom: 220px;

        .col-xs-12 {
            > img {
                position: absolute;
                top: 70px;
                
            }
        }
        .number-1 {
            position: absolute;
            top: -12px;
            left: 30px;
            > img {
                height: 310px;
            }
        }
        h3 {
            // margin-top: 54px;
            // margin-bottom: 25px;
            font-size: 54px;
        }
        p {
            font-size: 26px;
        }
    }

    
    #engine-protection {
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        // white overrides
        // background: #fff;
        // background: url('../../../../img/toptier-white-bg-line.png') no-repeat center center, #ffffff;
        // background: url('../../../../img/toptier-white-bg-line.png') no-repeat center center, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        padding-top: 202px;
        padding-bottom: 249px;
        .col-xs-12 {
            > img {
                position: absolute;
                top: -64px;
                height: 405px;
            }
        }
        .number-3 {
            position: absolute;
            top: -12px;
            left: 35px;
            > img {
                height: 310px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 54px;
        }
        p {
            font-size: 26px;
        }
    }

    #engine-protection.white-gradient {
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat center center, #ffffff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat center center, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
     }

    #engine-performance {
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat center center, #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat center center, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrides
        // background: #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 185px;
        padding-bottom: 185px;
        
        .col-xs-12 {
            > img {
                position: absolute;
                top: 35px;
                height: 350px;
            }
        }
        .number-4 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 359px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 54px;
        }
        p {
            font-size: 23px;
        }
    }
    
    #engine-cleanliness {
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat center center, #fff;
        background: url('../../../../img/toptier-white-bg-line.png') no-repeat center center, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // Blues
        // background: #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, #0092ff;
        // background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 180px;
        padding-bottom: 220px;

        .col-xs-12 {
            > img {
                position: absolute;
                top: 20px;
                left: 68px;
                height: auto;
            }
        }
        .number-4 {
            position: absolute;
            top: -12px;
            left: 30px;
            > img {
                height: 310px;
            }
        }
        h3 {
            // margin-top: 54px;
            // margin-bottom: 25px;
            font-size: 54px;
        }
        p {
            font-size: 26px;
        }
    }

    #engine-cleanliness.blue-gradient {
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    }

    #lower-emissions {
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, #fff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);

        // blue overrides
        background: #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, #0092ff;
        background: url('../../../../img/toptier-blue-bg-line.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);

        padding-top: 209px;
        padding-bottom: 206px;
        .col-xs-12 {
            > img {
                position: absolute;
                top: -24px;
                height: 420px;
            }
        }
        .number-5 {
            position: absolute;
            top: -12px;
            left: 17px;
            > img {
                height: 359px;
            }
        }
        hr {
            width: 100%;
        }
        h3 {
            font-size: 54px;
        }
        p {
            font-size: 23px;
        }
    }

    #for-less {
        height: 735px;
        background: url('../../../../img/toptier-forless-bg-shade.png') no-repeat center center, #0092ff;
        background: url('../../../../img/toptier-forless-bg-shade.png') no-repeat center center, linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        padding-top: 150px;
        
        //start hack
        //Had to add the below bit to mimic col-offset-1
        //which was removed from the markup to make room
        //for bigger piggybank/hula asset
        h3 {
            margin-left: 14.333%;
        }

        p {
            margin-left: 16%;
        }
        //end hack

        h3 {
            font-size: 54px;
            padding-bottom: 20px;
            
            width: 100%;
            position: absolute;
        }
        p {
            font-size: 26px;
            padding-right: 80px;
            
            padding-top: 110px;
        }
        .toptier-fineprint {
            padding-top: 20px;
            font-size: 15px;
            padding-right: 185px;
            margin-top: 0px;
        }
        .col-xs-12 {
            > img {
                height: 407px;
                position: absolute;
                top: 0px;
                left: -20px;
            }
        }
    }
}
