.pac-container {
    z-index: 2000;
}

.jumbotron-disclaimer {
    font-family: @arco-type-thin;
    position: relative;
    left: -0.5em;
    top: -1.35em;
    font-size: 0.35em;
}

.h3-disclaimer {
    font-family: @arco-type-thin;
    position: relative;
    top: -1.35em;
    font-size: 0.35em;
}

.h3-disclaimer-shifted {
    font-family: @arco-type-thin;
    position: relative;
    top: -1.35em;
    left: -0.25em;
    font-size: 0.35em;
}

.disclaimer {
    font-family: @arco-type-thin;
    position: relative;
    top: -0.75em;
    font-size: 0.5em;
}

.disclaimer-shift {
    font-family: @arco-type-thin;
    position: relative;
    top: -0.75em;
    left:-0.2em;
    font-size: 0.5em;
}


/*
 * Responsive text aligning
 * http://ohryan.ca/2014/08/14/set-responsive-text-alignment-bootstrap-3/
 */

.text-xs-left {
    text-align: left;
}

.text-xs-right {
    text-align: right;
}

.text-xs-center {
    text-align: center;
}

.text-xs-justify {
    text-align: justify;
}

@media (min-width: @screen-sm-min) {
    .text-sm-left {
        text-align: left;
    }
    .text-sm-right {
        text-align: right;
    }
    .text-sm-center {
        text-align: center;
    }
    .text-sm-justify {
        text-align: justify;
    }
}

@media (min-width: @screen-md-min) {
    .text-md-left {
        text-align: left;
    }
    .text-md-right {
        text-align: right;
    }
    .text-md-center {
        text-align: center;
    }
    .text-md-justify {
        text-align: justify;
    }
}

@media (min-width: @screen-lg-min) {
    .text-lg-left {
        text-align: left;
    }
    .text-lg-right {
        text-align: right;
    }
    .text-lg-center {
        text-align: center;
    }
    .text-lg-justify {
        text-align: justify;
    }
}

// Button Styles
.content-begin {
    .btn {
        box-shadow: 0 4px 7px -1px rgba(28, 28, 28, 0.5);
    }
}

// General 
.bootstrap-div {
    position: relative;
}

.notifier {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1031;
    font-size: 8px;
    color: white;
    opacity: 0.8;
}

body {
    // padding-top: 50px;
    font-family: 'DIN Next W01 Light';
}

a,
a:focus,
a:active,
a:hover {
    outline: none;
}

// Change the default navbar bg color
.navbar-default {
    background-color: #fff;
    border-color: #fff;
    opacity: 0.95;
}

.navbar-fixed-top .navbar-collapse {
    max-height: calc(100% - 50px);
}

// Change the hover states inherited by bootstrap navbar
.navbar-default .navbar-nav > li > a:hover {
    color: #8A8A8A;
    background-color: transparent;
}

.navbar-default .navbar-nav > li > a:focus {
    color: #000;
    background-color: transparent;
}

.navbar-default .navbar-nav > li:nth-child(5) > a:hover {
    color: #dedede;
    background-color: transparent;
}

.navbar-default .navbar-nav > li:nth-child(5) > a:focus {
    color: #fff;
    background-color: transparent;
}

// Desktop navbar is a different chunk of HTML
.desktop-navbar {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.8);
    // margin: 0 -4px;
    height: 100px;
    .container {
        height: 100px;
    }
    ul.headernav {
        li {
            a > img {
                display: inline-block;
                ;
            }
            a > span {
                display: inline-block;
                vertical-align: middle;
                padding-left: 3px;
            }
        }
        li.underlinenav:not(:first-of-type) {
            // li.underlinenav {
            > div.redline {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: @brand-arco-red;
            }
        }
    }
    // Logo
    li:nth-child(1) {
        border-right: 1px solid #666666;
        a {
            color: #333333;
        }
    }
    // Quality top Tier Gas for Less
    li:nth-child(2) {
        border-right: 1px solid #666666;
        a {
            color: #333333;
        }
    }
    // Payment options
    li:nth-child(3) {
        border-right: 1px solid #666666;
        a {
            color: #333333;
        }
    }
    // Say Hello
    li:nth-child(4) {
        a {
            color: #333333;
        }
    }
    // Station Near Your
    li:nth-child(5) {
        background: #0092ff;
        background: -moz-linear-gradient(top, #0092ff 0%, #0061b1 100%);
        background: -webkit-linear-gradient(top, #0092ff 0%, #0061b1 100%);
        background: linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
        a {
            font-family: "DIN Next W01 Medium";
            color: #FFFFFF;
        }
    }
    li > a {
        font-family: "DIN Next W01 Bold";
        display: table-cell;
        vertical-align: middle;
        color: #333333;
    }
    .nav-station-result {
        font-family: 'DIN Next W01 Light';
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
}

.navbar-collapse {
    height: 100vh;
}

// This fixes the bootstrap collapse menu animation stagger
.no-transition {
    -webkit-transition: height 0.01s;
    -moz-transition: height 0.01s;
    -ms-transition: height 0.01s;
    -o-transition: height 0.01s;
    transition: height 0.01s;
}

.mobile-locator-subpanel {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.8);
    margin: 0 -4px;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1029;
    height: 64px;
    background-color: #005dab;
}

// Mobile navbar styles
.mobile-navbar {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.8);
    margin: 0 -4px;
    .container:nth-child(1) {
        padding-left: 0;
        padding-right: 0;
        .icon-bar {
            background-color: #ed2a3c;
        }
        .navbar-toggle {
            border-color: transparent;
        }
        .navbar-brand {
            img {
                height: 20px;
            }
        }
        .navbar-nav {
            > li {
                > a {
                    font-size: 19px;
                    padding-top: 19px;
                    padding-bottom: 16px;
                }
            }
            > hr {
                border-top: 2px solid @brand-arco-red;
                margin: 0 auto;
                padding: 0;
                width: calc(100% - 30px);
            }
        }
    }
}

.footer-container {
    padding-top: 45px;
    padding-bottom: 45px;

    //BLUE FOOTER
    // color: #fff;
    // background: #0092ff;
    // background: -moz-linear-gradient(top, #0092ff 0%, #0061b1 100%);
    // background: -webkit-linear-gradient(top, #0092ff 0%, #0061b1 100%);
    // background: linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
    
    //WHITE FOOTER
    color: #333;
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
    background: -webkit-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
    a,
    a:link,
    a:visited,
    a:focus,
    a:active {
        //color: #fff;
        color: #333;
        text-decoration: underline;
    }
    a:hover {
        // color: @brand-arco-red;
        color: #004986;
    }
    .col-xs-8 {
        text-align: left;
    }
    .col-xs-4 {
        text-align: right;
    }
    .footer-disclaimer {
        font-size: 10px;
    }
    .row.visible-xs-block {
        font-size: 11px;
        padding-top: 30px;
        padding-bottom: 40px;
        line-height: 40px;
    }
    .row.hidden-xs {
        font-size: 11px;
        padding-top: 40px;
        padding-bottom: 50px;
    }
    .fa {
        cursor: pointer;
    }
    .underline-text {
        text-decoration: none;
        cursor: pointer;
    }
    .underline-text:hover {
        // color: @brand-arco-red;
        text-decoration: underline;
        //color: #fff;
        color: #333;
    }
}

#header_panel {
    .blue-gradient {
        color: #fff;
        background: #0092ff;
        background: @arco-blue-gradient;
        font-family: @arco-type-medium;
    }
}

// MOBILE FIRST APPROACH, MODIFY AS I GO UP WHEN NEEDED TO RESIZE FOR THE LARGER DEVICE
@media(max-width: @screen-xs-max) {
    .navbar-default .navbar-collapse {
        border-top-color: #fff;
    }
    .btn {
        font-family: 'DIN Next W01 Medium';
    }
    .pump-image {
        position: absolute;
        top: 14px;
        left: 15px;
        height: 38px;
    }
    .mobile-locator-subpanel {
        .col-xs-12 {
            position: relative;
            padding-top: 15px;
            padding-left: 50px;
            padding-right: 10px;
        }
        .input-group {
            display: inline-block;
            width: 100%;
        }
        .form-control {
            width: calc(100% - 49px);
            padding-left: 36px;
        }
        .location-icon {
            position: absolute;
            width: 25px;
            height: 25px;
            background: url('../../../../img/locator-location-empty.png') no-repeat top left;
            background-size: contain;
            top: 4px;
            left: 5px;
            z-index: 100;
            // cursor: pointer;
        }
        .location-icon.haslocation {
            position: absolute;
            width: 25px;
            height: 25px;
            background: url('../../../../img/locator-location-filled.png') no-repeat top left;
            background-size: contain;
            top: 4px;
            left: 5px;
            z-index: 100;
        }
    }
    .content-begin {
        padding-top: 64px;
    }
    .station-finder {
        .content-begin {
            padding-top: 0;
        }
    }
    .stationfinder .content-begin {
        padding-top: 0px;
    }
    /* enable absolute positioning */
    .inner-addon {
        position: relative;
    }
    /* style icon */
    .inner-addon .location-icon {
        position: absolute;
        padding: 10px;
        // pointer-events: none;
    }
    /* align icon */
    .left-addon .location-icon {
        left: 0px;
    }
    .right-addon .location-icon {
        right: 0px;
    }
    /* add padding  */
    .left-addon input {
        padding-left: 30px;
    }
    .right-addon input {
        padding-right: 30px;
    }
    .footer-container {
        .col-xs-12 {
            > a img {
                height: 23px;
            }
            a:nth-child(2) {
                padding-left: 13px;
                padding-right: 13px;
            }
        }
    }
}

// Min Width = 768px (Tablets)
@media(min-width: @screen-sm) {
    .btn {
        font-family: @arco-type-medium;
    }
    .desktop-navbar {
        height: 80px;
        .container {
            height: 80px;
            position: relative;
            // background-color: #c0c0c0;
            #header_panel {
                form {
                    display: inline-block;
                    vertical-align: middle;
                }
                .blue-gradient {
                    font-size: 13px;
                }
                .row.blue-gradient {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 11px;
                    .gas-pump {
                        width: 24px;
                        height: auto;
                    }
                    .arco-caret-right {
                        position: absolute;
                        width: 18px;
                        height: 21px;
                        background: url('../../../../img/nav-right-caret.png') no-repeat top left;
                        background-size: contain;
                        top: 11px;
                        right: 15px;
                        cursor: pointer;
                    }
                    input {
                        color: @arco-darkcopy;
                        font-family: @arco-type-light;
                        width: 115px;
                        font-size: 9px;
                    }
                }
                position: absolute;
                padding: 0px 15px 0px 15px;
                right: 0px;
                width: 320px;
                // height: 400px;
                // max-height: 400px !important;
                background: #ffffff;
                background: @arco-white-gradient;
                overflow-y: hidden;
                overflow-x: hidden;
                .row {
                    border-bottom: 2px solid #333;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    cursor: pointer;
                }
                .location-item,
                .location-miles {
                    font-family: @arco-type-bold;
                    font-size: 10px;
                }
                .location-item {
                    padding-left: 20px;
                }
                .location-miles {
                    padding-right: 45px;
                }
                .arco-caret-right {
                    position: absolute;
                    width: 18px;
                    height: 21px;
                    background: url('../../../../img/nav-right-caret.png') no-repeat top left;
                    background-size: contain;
                    top: -1px;
                    right: 15px;
                    cursor: pointer;
                }
            }
        }
        ul.headernav {
          li > a:hover {
            color: #c0c0c0;
          }
        }
        ul {
            width: 100%;
            li > a {
                font-size: 11px;
                line-height: 13px;
                height: 80px;
            }
            // li > a:hover {
                
            // }
            li:nth-child(1) {
                width: 20%;
                a > img {
                    width: 80%;
                }
                a {
                    padding-left: 11px;
                    padding-right: 1px;
                }
            }
            // ARCO Value
            li:nth-child(2) {
                width: 18%;
                a {
                    padding-left: 24px;
                    padding-right: 1px;
                }
                img {
                    width: 24%;
                }
            }
            // Payment options
            li:nth-child(3) {
                width: 18%;
                a {
                    padding-left: 18px;
                    padding-right: 1px;
                    span {
                        padding-left: 4px;
                    }
                }
                img {
                    width: 24%;
                }
            }
            // Say Hello
            li:nth-child(4) {
                width: 18%;
                a {
                    padding-left: 28px;
                    padding-right: 1px;
                    span {
                        padding-left: 4px;
                    }
                }
                img {
                    width: 24%;
                }
            }
            // Station Near Your
            li:nth-child(5) {
                width: 26%;
                a {
                    padding-left: 12px;
                    padding-right: 0;
                }
                img {
                    width: 16%;
                }
                .arco-caret {
                    position: absolute;
                    width: 19px;
                    height: 21px;
                    background: url('../../../../img/nav-down-caret.png') no-repeat top left;
                    background-size: contain;
                    top: 30px;
                    right: 15px;
                    cursor: pointer;
                }
                .arco-caret.upcaret {
                    background: url('../../../../img/nav-up-caret.png') no-repeat top left;
                    width: 19px;
                    height: 21px;
                    background-size: contain;
                }
                .arco-caret-right {
                    position: absolute;
                    width: 18px;
                    height: 21px;
                    background: url('../../../../img/nav-right-caret.png') no-repeat top left;
                    background-size: contain;
                    top: 38px;
                    right: 15px;
                    cursor: pointer;
                }
                input {
                    color: @arco-darkcopy;
                    font-family: @arco-type-light;
                    width: 115px;
                    font-size: 9px;
                }
            }
        }
        .nav-station-result {
            width: 113px;
            font-size: 10px;
        }
    }
    .content-begin {
        padding-top: 0;
    }
    .footer-container {
        .row.visible-sm-block {
            font-size: 16px;
            line-height: 56px;
        }
        .col-xs-12 {
            > a img {
                height: 23px;
            }
            a:nth-child(2) {
                padding-left: 22px;
                padding-right: 22px;
            }
        }
    }
}

// Min Width = 992px (Medium Desktop)
@media (min-width: @screen-md) {
    .desktop-navbar {
        height: 100px;
        .container {
            height: 100px;
            // background-color: #c0c0c0;
            #header_panel {
                width: 385px;
                form {
                    display: inline-block;
                    vertical-align: middle;
                }
                .blue-gradient {
                    font-size: 13px;
                }
                .row.blue-gradient {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 13px;
                    .gas-pump {
                        width: 38px;
                    }
                    .arco-caret-right {
                        width: 26px;
                        height: 30px;
                        top: 18px;
                        right: 15px;
                    }
                    input {
                        width: 137px;
                        font-size: 10px;
                    }
                }
                .row {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
                .location-item,
                .location-miles {
                    font-family: @arco-type-bold;
                    font-size: 14px;
                }
                .location-item {
                    padding-left: 22px;
                }
                .location-miles {
                    padding-right: 54px;
                }
                .arco-caret-right {
                    position: absolute;
                    width: 26px;
                    height: 30px;
                    background: url('../../../../img/nav-right-caret.png') no-repeat top left;
                    background-size: contain;
                    top: -1px;
                    right: 15px;
                    cursor: pointer;
                }
            }
        }
        ul {
            // width: 100%;
            li > a {
                font-size: 13px;
                line-height: 15px;
                height: 100px;
            }
            li:nth-child(1) {
                // width: 20%;
                a > img {
                    width: 90%
                }
            }
            // ARCO Value
            li:nth-child(2) {
                // width: 22%;
                a {
                    padding-left: 30px;
                }
                img {
                    width: initial;
                }
            }
            // Payment options
            li:nth-child(3) {
                // width: 17%;
                a {
                    padding-left: 25px;
                    span {
                        padding-left: 4px;
                    }
                }
                img {
                    width: initial;
                }
            }
            // Say Hello
            li:nth-child(4) {
                // width: 15%;
                a {
                    padding-left: 25px;
                    span {
                        padding-left: 4px;
                    }
                }
                img {
                    width: initial;
                }
            }
            // Station Near Your
            li:nth-child(5) {
                // width: 26%;
                a {
                    padding-left: 25px;
                }
                img {
                    width: initial;
                }
                .arco-caret {
                    position: absolute;
                    width: 26px;
                    height: 30px;
                    background: url('../../../../img/nav-down-caret.png') no-repeat top left;
                    background-size: contain;
                    // top: 25px;
                    right: 13px;
                    cursor: pointer;
                    top: 35px;
                }
                .arco-caret.upcaret {
                    width: 26px;
                    height: 30px;
                }
                .arco-caret-right {
                    position: absolute;
                    width: 25px;
                    height: 28px;
                    background: url('../../../../img/nav-right-caret.png') no-repeat top left;
                    background-size: contain;
                    top: 48px;
                    right: 15px;
                    cursor: pointer;
                }
                input {
                    color: @arco-darkcopy;
                    font-family: @arco-type-light;
                    width: 137px;
                    font-size: 10px;
                }
            }
        }
        .nav-station-result {
            width: 140px;
            font-size: 12px;
        }
    }
    .footer-container {
        .row.hidden-sm {
            line-height: 56px;
            font-size: 16px;
        }
    }
}

// Min width = 1200px (Large Desktop)
@media (min-width: @screen-lg) {
    .desktop-navbar {
        .container {
            #header_panel {
                width: 467px;
                .blue-gradient {
                    font-size: 15px;
                }
                .row.blue-gradient {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-size: 15px;
                    .gas-pump {
                        width: 38px;
                    }
                    .arco-caret-right {
                        width: 32px;
                        height: 35px;
                        top: 15px;
                    }
                    input {
                        margin-left: 10px;
                        width: 172px;
                        font-size: 13px;
                    }
                }
                .row {
                    padding-top: 33px;
                    padding-bottom: 33px;
                }
                .location-item,
                .location-miles {
                    font-family: @arco-type-bold;
                    font-size: 15px;
                }
                .location-miles {
                    padding-right: 60px;
                }
                .arco-caret-right {
                    position: absolute;
                    width: 32px;
                    height: 35px;
                    background: url('../../../../img/nav-right-caret.png') no-repeat top left;
                    background-size: contain;
                    top: -3px;
                    right: 15px;
                    cursor: pointer;
                }
            }
        }
        ul {
            // width: 100%;
            li > a {
                font-size: 15px;
                line-height: 16px;
                height: 100px;
            }
            li:nth-child(1) {
                // width: 20%;
                a > img {
                    width: 100%
                }
            }
            // ARCO Value
            li:nth-child(2) {
                // width: 22%;
                a {
                    padding-left: 40px;
                }
            }
            // Payment options
            li:nth-child(3) {
                // width: 17%;
                a {
                    padding-left: 40px;
                    span {
                        padding-left: 4px;
                    }
                }
            }
            // Say Hello
            li:nth-child(4) {
                // width: 15%;
                a {
                    padding-left: 40px;
                    span {
                        padding-left: 4px;
                    }
                }
            }
            // Station Near Your
            li:nth-child(5) {
                // width: 26%;
                a {
                    padding-left: 30px;
                }
                .arco-caret {
                    position: absolute;
                    width: 32px;
                    height: 35px;
                    background: url('../../../../img/nav-down-caret.png') no-repeat top left;
                    // top: 25px;
                    right: 15px;
                    cursor: pointer;
                    top: 33px;
                }
                .arco-caret.upcaret {
                    width: 32px;
                    height: 35px;
                }
                .arco-caret-right {
                    position: absolute;
                    width: 32px;
                    height: 35px;
                    background: url('../../../../img/nav-right-caret.png') no-repeat top left;
                    background-size: contain;
                    top: 44px;
                    right: 15px;
                    cursor: pointer;
                }
                input {
                    color: @arco-darkcopy;
                    font-family: @arco-type-light;
                    width: 166px;
                    font-size: 13px;
                }
            }
        }
    }
}

// Dot Navigation {}
.affix-top {
    top: 150px;
    right: 0;
    position: absolute;
    z-index: 2;
    position: fixed;
}

.affix {
    top: 150px;
    right: 0;
    z-index: 2;
}

#dotNav,
#dotNav2 {
    z-index: 5;
    right: 0;
    padding: 0px;
    padding-top: 12px;
    // display: none;
}

#dotNav,
#dotNav2 {
    ul {
        list-style: none;
        margin: 0;
        padding-left: 10px;
        background-color: #fff
    }
}

#dotNav,
#dotNav2 {
    li {
        position: relative;
        background: none repeat scroll 0 0 #fff;
        border: 1px solid @brand-arco-red;
        border-radius: 15px 15px 15px 15px;
        cursor: pointer;
        height: 12px;
        margin: 10px 10px 0px 0px;
        width: 12px;
        vertical-align: bottom;
    }
}

#dotNav,
#dotNav2 {
    li:nth-child(1) {
        margin-top: 0px;
    }
}

#dotNav,
#dotNav2 {
    li.active {
        background-color: @brand-arco-red;
        // background-image: -moz-linear-gradient(center top, #FFFFFF, #EEEEEE);
        // background-repeat: repeat-x;
    }
}

#dotNav,
#dotNav2 {
    li:hover {
        //background: none repeat scroll 0 0 #EEEEEE;
    }
}

#dotNav,
#dotNav2 {
    a {
        outline: 0;
        vertical-align: top;
        margin: 0px 0px 0px 25px;
        position: relative;
        top: -5px;
    }
}

.hidden-submit {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
}

// Other utils 
.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}