//MOBILE
.m-home {
  overflow-x: hidden;
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}


//START -- GOODWILL LA PROMO STYLING -- START
//START -- GOODWILL LA PROMO STYLING -- START
//START -- GOODWILL LA PROMO STYLING -- START
.good-la-promo {
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
  color: #1958AB;
  padding: 20px;

  h2 {
    font-family: @arco-type-bold;
  }

  p {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 35px;
    letter-spacing: 0.5px;
  }

  @min_width_gw: 320;
  @max_width_gw: 414;

  @min_h2: 44;
  @max_h2: 60;


    body.modal-open {
        padding-right: 0 !important;
    }

  @media (min-width: ~"@{min_width_gw}px") and (max-width: ~"@{max_width_gw}px") {
    h2 {
      font-size:~"calc( @{min_h2}px + (@{max_h2} - @{min_h2}) * ( (100vw - @{min_width_gw}px) / ( @{max_width_gw} - @{min_width_gw}) ));"
    }
  }

  @media (min-width: ~"@{max_width_gw}px") {
    h2 {
      font-size:~"@{max_h2}px";
    }
  } //iPad and up
  @media (min-width: 768px) {
    h2 {
      font-size: 54px;
    }
    p {
      margin-bottom: 15px;
    }
  }

  @media (min-width: 992px) {
    padding: 35px;

    h2 {
      font-size: 60px;
    }
    p {
      font-size: 18px;
    }
  }

  @media (min-width: 1200px) {
    h2 {
      font-size: 68px;
    }
    p {
      font-size: 20px;
    }
  }
}


//END -- GOODWILL LA PROMO STYLING -- END
//START -- ARCO MX PROMO BANNER STYLING -- START
.arco-mx-promo {
  position: absolute;
  top: 0;
  background-color: rgba(235, 235, 235, .5);
  width: 100%;
  height: 60px;
  .col-xs-7 {
    //padding-left: 5px;
    padding-right: 5px;
  }
  .col-xs-5 {
    padding-left: 0px;
    a.btn {
      font-size: 11px;
    }
    img {
      width: 15px;
      margin-left: 15px;
    }
  }

  .promo-copy {
    padding-top: 10px;
    h2 {
      font-size: 11px;
      font-family: @arco-type-medium;
      margin: 0;
      line-height: 1.5;
    }
  }
}

.main-pano-copy.show-promo {
  top: 60px !important;
}

@media(min-width: 414px) and (max-width: 767px) {
  .arco-mx-promo {
    .col-xs-5 {
      padding-top: 3px;
      padding-left: 30px;
      a.btn {}
    }
    .promo-copy {
      h2 {
        padding-top: 5px;
        font-size: 11px;
      }
    }
  }
}

//iPad and up
@media(min-width: 768px) {
  .arco-mx-promo {
    .col-xs-5 {
      padding-top: 3px;
      padding-left: 30px;
      a.btn {
        font-size: 14px;
      }
      img {
        margin-left: 45px;
      }
    }
    .promo-copy {
      h2 {
        padding-top: 0px;
        font-size: 14px;
      }
    }
  }
}

@media(min-width: 992px) {
  .main-pano-copy.show-promo {
    top: 80px !important;
  }
  .arco-mx-promo {
    height: 80px;

    .col-xs-5 {

      a.btn {
        font-size: 16px;
      }
      img {}
    }
    .promo-copy {
      padding-top: 13px;
      h2 {
        font-size: 18px;
      }
    }
  }
}

//END -- ARCO MX PROMO BANNER STYLING -- END
//START -- HULA GIRL PROMO STYLING -- START
.hula-girl-promo {
  @min_width_: 320;
  @max_width_: 767; // background: #0054A8;
  // background: -moz-radial-gradient( #1858AC 70%, #0054A8 100%);
  // background: -webkit-radial-gradient( #1858AC 70%, #0054A8 100%);
  // background: radial-gradient( #1858AC 70%, #0054A8 100%);
  background: -webkit-linear-gradient(#0054A8, #215DAC, #215DAC, #0054A8);
  /* Safari 5.1-6.0 */
  background: -o-linear-gradient(#0054A8, #215DAC, #215DAC, #0054A8);
  /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(#0054A8, #215DAC, #215DAC, #0054A8);
  /* Firefox 3.6-15 */
  background: linear-gradient(#0054A8, #215DAC, #215DAC, #0054A8);
  /* Standard syntax */
  font-family: @arco-type-medium;
  color: white;
  padding-top: 10px;

  .container {
    padding: 0px;

    .row {
      padding-bottom: 10px;

      .hulaQuoteBox {

        @media(max-width: @screen-sm-max) {

          padding-left: 15px; //padding-right: 10px;
        }
      }
    }

    .hula-img-frame {

      position: relative;


      @min_img_width: 280;
      @max_img_width: 385;

      img {


        @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {

          width:~"calc( @{min_img_width}px + (@{max_img_width} - @{min_img_width}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
        }

        width: 75%;
        position: absolute;
        bottom: 0;
        right: -50px;

        @media(min-width: @screen-sm) {
          right: -40px;
          width: 425px;
        }

        @media(min-width: @screen-md) {
          right: -20px;
          width: 525px;
        }

        @media(min-width: @screen-lg) {
          width: 630px;
          right: 0px;
        }
      }
    }
  }

  @max_font_h2: 24;
  @min_font_h2: 14;

  h2 {
    font-size: 18px;
    margin-top: 15px; // @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {
    //     font-size: ~"calc( @{min_font_h2}px + (@{max_font_h2} - @{min_font_h2}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
    // }
  }



  @max_line: 20;
  @min_line: 16;

  p {
    font-family: @arco-type-thin;
    font-size: 13px;
    line-height: 18px; // @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {
    //     font-size: ~"calc( @{min_p}px + (@{max_p} - @{min_p}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));";
    //     line-height: ~"calc( @{min_line}px + (@{max_line} - @{min_line}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
    // }
  }
}

@media(min-width: @screen-sm) {
  .hula-girl-promo {
    .container {
      .row {
        padding-bottom: 25px;

        .hulaQuoteBox {}
      }
    }


    h2 {
      font-size: 24px;
      margin-top: 25px;
      letter-spacing: 1px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
}

@media(min-width: @screen-md) {
  .hula-girl-promo {

    padding-top: 20px;
    .container {
      .row {
        padding-bottom: 30px;

        .hulaQuoteBox {
          padding-left: 15px;
          padding-right: 30px;
        }
      }
    }

    h2 {
      font-size: 32px;
      margin-top: 25px;
      letter-spacing: 1px;
    }
    p {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
}

@media(min-width: @screen-lg) {
  .hula-girl-promo {

    padding-top: 60px;
    .container {
      .row {
        padding-bottom: 80px;

        .hulaQuoteBox {
          padding-left: 15px;
          padding-right: 50px;
        }
      }
    }

    h2 {
      font-size: 35px;
      margin-top: 35px;
      letter-spacing: 1px;
    }
    p {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 1px;
    }
  }
}

//END -- HULAGIRL PROMO STYLING -- END
//START -- SOCAL GOODWILL PROMO STYLING -- START
.socal-goodwill-promo {

  font-family: @arco-type-medium;
  color: #1858AC;
  background-image: -webkit-radial-gradient(#f0f0f0 0%, #f0f0f0 45%, #DEDEDE 100%);
  background-image: -o-radial-gradient(#f0f0f0 0%, #f0f0f0 45%, #DEDEDE 100%);
  background-image: radial-gradient(#f0f0f0 0%, #f0f0f0 45%, #DEDEDE 100%);


  @media (min-width: @screen-lg) {
    
  }

  .left-bumper {
    margin-left: 2.5%;
    @media (max-width: @screen-lg-min) {
      margin-left: 0%;
    }
    @media (max-width: @screen-xs-max) {
      padding-left: 0px;
    }
  }


  .bottom {
    position: relative;
    @min_width_: 320;
    @max_width_: 767;
    @min_img_width: 200;
    @max_img_width: 280;

    .goodwill-portrait {
      position: absolute;
      bottom: 0;
      right: 35px; //overflow-x: hidden;
      @media (max-width: @screen-lg-min) {
        right: -30px;
      }

      img {
        width: 361px;

        @media (max-width: @screen-sm-min) {
          width: 330px;
        }

        @media (max-width: @screen-xs-max) {
          //width: 201px;
        }
        @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {

          width:~"calc( @{min_img_width}px + (@{max_img_width} - @{min_img_width}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
        } // @media (min-width: ~"@{max_width_}px") {
        //     width: ~"@{max_img_width}px;"
        // }
      }
    }
  }

  .top {
    background-color: #0054A8; //darker blue for top half
    padding-top: 15px;
    .quoteName {

      text-align: right;
      color: white;
      font-family: @arco-type-light; // These values are the minimum and maximum 
      // viewport sizes to apply the font scaling
      @min_width_: 320;
      @max_width_: 767; // These values represent the range of font sizes to apply  
      // These values effect the base font-size, headings and 
      // other elements will scale proportionally
      @min_font_h4: 15;
      @max_font_h4: 26;

      h4 {
        font-size: 2.2em;
        padding-right: 75px;
        text-align: right;

        @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {
          font-size:~"calc( @{min_font_h4}px + (@{max_font_h4} - @{min_font_h4}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
        }
        @media (min-width: ~"@{max_width_}px") {
          //font-size: ~"@{max_font_h4}px;"
        }

        @media (max-width: @screen-xs-max) {
          font-family: @arco-type-thin; //font-size: 1.1em;
          padding-right: 70px;
          padding-top: 5px;
        }
      }
    }




    .quoteBox {

      //position: relative;
      //z-index: -99;
      padding-top: 45px; //padding-bottom: 25px;
      margin: 0 auto;
      text-align: center;
      @media (max-width: @screen-lg-min) {

        padding-top: 35px;
      }

      @media (max-width: @screen-md-min) {
        text-align: left;
        padding-top: 25px;
        padding-left: 0px;
      }

      @media (max-width: @screen-xs-max) {
        padding-top: 12px;
      }


      .dblQuote {

        font-family: @arco-type-bold;
        display: inline-block;
        font-size: 12em;
        z-index: 1;
        color: #3D87C0;
        position: absolute;
        opacity: 0.9;
        margin-left: -20px;

        @media (max-width: @screen-xs-max) {
          font-size: 7em;
        }

        &.left {

          line-height: .7;
          @media (max-width: @screen-xs-max) {
            left: 5px;
          }
        }

        &.right {

          line-height: 0.95;
        }
      } // These values are the minimum and maximum 
      // viewport sizes to apply the font scaling
      @min_width_: 320;
      @max_width_: 767; // These values represent the range of font sizes to apply  
      // These values effect the base font-size, headings and 
      // other elements will scale proportionally
      @min_font_: 15;
      @max_font_: 26;




      h2 {
        font-family: @arco-type-medium;
        font-size: 2.25em;
        color: white;
        display: inline;
        line-height: 1.15;
        padding-left: 10px;
        letter-spacing: .5px;
        z-index: 2;
        position: inherit;

        @media (max-width: @screen-md-min) {
          font-size: 1.8em;
        }
        @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {
          font-size:~"calc( @{min_font_}px + (@{max_font_} - @{min_font_}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
        }
        @media (min-width: ~"@{max_width_}px") {
          //font-size: ~"@{max_font_}px;"
        }
        @media (max-width: @screen-xs-max) {
          //font-size: 1.1em;
          padding-left: 0px;
          letter-spacing: 0.25px;
        }
      }
    }
  }


  @min_width_: 320;

  @max_width_mob: 414;

  @max_width_: 767;

  @min_font_h1: 36;
  @max_font_h1: 42;

  .blue-div {
    border-top: 2px solid #1858AC;
    width: 90%;
    max-width: 960px;
    margin: 0 auto;
  }

  h1.goodwill-bold-heading {
    font-family: @arco-type-bold;
    font-size: 96px;
    margin-top: 40px;
    margin-bottom: 15px;
    line-height: 88px;

    @media (max-width: @screen-lg) {
      font-size: 76px;
      line-height: 70px;
    }

    @media (max-width: @screen-md) {
      font-size: 68px;
      line-height: 64px;
    }


    @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_mob}px") {

      font-size:~"calc( @{min_font_h1}px + (@{max_font_h1} - @{min_font_h1}) * ( (100vw - @{min_width_}px) / ( @{max_width_mob} - @{min_width_}) ));";
      margin-top: 34px;
      line-height: 38px;
    }

    @media (min-width: 415px) and (max-width: @screen-xs-max) {
      margin-top: 34px;
      line-height: 40px;
      font-size: 45px;
    }
  }

  h2.goodwill-bold-heading {
    font-size: 42px;
    font-family: @arco-type-regular;
    margin-top: 15px;

    @media (max-width: @screen-lg) {
      font-size: 32px;
      margin-top: 0;
    }

    @media (max-width: @screen-md) {
      font-size: 28px;
    }

    @media (max-width: @screen-sm) {

      font-size: 26px;
    }
  }


  @min_font_h3: 11;
  @max_font_h3: 15;
  h3 {
    font-family: @arco-type-light;
    padding-top: 32px;
    padding-bottom: 25px;
    line-height: 1.35;
    margin: 0 auto;
    font-size: 26px; //width: 95%; // opacity: .9;
    text-align: center;
    color: #636363;

    .goodwill-hash {
      font-family: @arco-type-bold;
    }

    @media (max-width: @screen-lg-min) {
      //font-size: 18px;
      padding-top: 32px;
      font-size: 22px;
    }

    @media (max-width: @screen-md-min) {
      //text-align: left;
      margin-left: 0;
      padding-bottom: 25px;
      font-size: 20px;
    }

    @media (max-width: @screen-sm-min) {
      //text-align: left;
      margin-left: 0;
      font-size: 18px;
    }

    @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_mob}px") {

      font-size:~"calc( @{min_font_h3}px + (@{max_font_h3} - @{min_font_h3}) * ( (100vw - @{min_width_}px) / ( @{max_width_mob} - @{min_width_}) ));"
    }



    @media (max-width: @screen-xs-max) {
      padding-top: 20px;
      padding-bottom: 15px; //width: 100%;
      text-align: left;
      letter-spacing: 0.2px; //font-size: 9.5px;
    }
  } //@min_width_: 400;
  //@max_width_: 767;
  @min_font_motto: 8;
  @max_font_motto: 16;


  h4 {
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 35px; // font-size: 24px;
    font-size: 16px; // opacity: .9;
    font-family: @arco-type-medium;
    text-align: center;
    /*color: #848484;*/

    @media (max-width: @screen-md-min) {
      /*text-align: left;*/
      margin-left: 0;
    }
    @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {

      font-size:~"calc( @{min_font_motto}px + (@{max_font_motto} - @{min_font_motto}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
    }
    @media (min-width: ~"@{max_width_}px") {

      //font-size: ~"@{max_font_motto}px;"
    }
    @media (max-width: @screen-xs-max) {
      padding-top: 10px;
      padding-bottom: 10px; //font-size: 8px;
      
    }
  }

  @min_logo_width: 145;
  @max_logo_width: 300;

  img {
    width: 300px;
    padding-top: 21px;
    margin: 0 auto;

    @media (min-width: @screen-md-min) {
      //padding-top: 10px;
      //margin-left: 0;
      padding-top: 35px;
    }

    @media (max-width: @screen-sm-min) {}

    @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {

      width:~"calc( @{min_logo_width}px + (@{max_logo_width} - @{min_logo_width}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
    }

    @media (max-width: @screen-xs-max) {
      //width: 145px;
      padding-top: 30px;
    }
  }

  #donation-button {
    display: block;
    width: 120px;
    padding: 2px 0px;
    padding-top: 5px;
    color: #0d63ae;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    margin: 0 auto;
    line-height: 1.15;
    border-radius: 10px;
  }
}

//START -- New SOCAL GOODWILL PROMO STYLING -- START
.socal-goodwill-promo-new {



  font-family: @arco-type-medium;
  color: #fff;
  background: #055aa9;
  background: -moz-linear-gradient(top, #055aa9 0%, #003772 100%);
  background: -webkit-linear-gradient(top, #055aa9 0%,#003772 100%);
  background: linear-gradient(to bottom, #055aa9 0%,#003772 100%);

  .fine-print {
      font-size:10px;
  }

  .finer-print {
      font-size:6px;
  }

  @media (min-width: @screen-lg) {
    
  }

  .left-bumper {
    margin-left: 2.5%;
    @media (max-width: @screen-lg-min) {
      margin-left: 0%;
    }
    @media (max-width: @screen-xs-max) {
      padding-left: 0px;
    }
  }

  .container-frame {
      margin:15px 20px 15px 20px;
      border:10px solid #fff;
  }



  .bottom {
    position: relative;
    @min_width_: 320;
    @max_width_: 767;
    @min_img_width: 200;
    @max_img_width: 280;

    .goodwill-portrait {
      position: absolute;
      bottom: -25px;
      right: 10px; //overflow-x: hidden;
      @media (max-width: @screen-lg-min) {
        right: -30px;
      }

      img {
        width: 410px;

        @media (max-width: @screen-lg) {
        width: 370px;
        }

        @media (max-width: @screen-md) {
        width: 410px;
        }

        @media (max-width: @screen-sm-min) {
          width: 330px;
        }

        @media (max-width: @screen-xs-max) {
          //width: 201px;
        }
        @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {

          width:~"calc( @{min_img_width}px + (@{max_img_width} - @{min_img_width}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
        } // @media (min-width: ~"@{max_width_}px") {
        //     width: ~"@{max_img_width}px;"
        // }
      }
    }
  }


    &.local {
      .bottom {
        position: relative;
        @min_width_: 320;
        @max_width_: 767;
        @min_img_width: 200;
        @max_img_width: 280;

        .goodwill-portrait {
          position: absolute;
          bottom: -25px;
          right: 10px; //overflow-x: hidden;
          @media (max-width: @screen-lg-min) {
            right: -30px;
          }

          img {
            width: 510px;

            @media (max-width: @screen-lg) {
            width: 440px;
            }

            @media (max-width: @screen-md) {
            width: 460px;
            }

            @media (max-width: @screen-sm-min) {
              width: 330px;
            }

            @media (max-width: @screen-xs-max) {
              //width: 201px;
            }
            @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {

              width:~"calc( @{min_img_width}px + (@{max_img_width} - @{min_img_width}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
            } // @media (min-width: ~"@{max_width_}px") {
            //     width: ~"@{max_img_width}px;"
            // }
          }
        }
      }
    }


  .top {
    background-color: #0054A8; //darker blue for top half
    padding-top: 15px;
    .quoteName {

      text-align: right;
      color: white;
      font-family: @arco-type-light; // These values are the minimum and maximum 
      // viewport sizes to apply the font scaling
      @min_width_: 320;
      @max_width_: 767; // These values represent the range of font sizes to apply  
      // These values effect the base font-size, headings and 
      // other elements will scale proportionally
      @min_font_h4: 15;
      @max_font_h4: 26;

      h4 {
        font-size: 2.2em;
        padding-right: 75px;
        text-align: right;

        @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {
          font-size:~"calc( @{min_font_h4}px + (@{max_font_h4} - @{min_font_h4}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
        }
        @media (min-width: ~"@{max_width_}px") {
          //font-size: ~"@{max_font_h4}px;"
        }

        @media (max-width: @screen-xs-max) {
          font-family: @arco-type-thin; //font-size: 1.1em;
          padding-right: 70px;
          padding-top: 5px;
        }
      }
    }




    .quoteBox {

      //position: relative;
      //z-index: -99;
      padding-top: 45px; //padding-bottom: 25px;
      margin: 0 auto;
      text-align: center;
      @media (max-width: @screen-lg-min) {

        padding-top: 35px;
      }

      @media (max-width: @screen-md-min) {
        text-align: left;
        padding-top: 25px;
        padding-left: 0px;
      }

      @media (max-width: @screen-xs-max) {
        padding-top: 12px;
      }


      .dblQuote {

        font-family: @arco-type-bold;
        display: inline-block;
        font-size: 12em;
        z-index: 1;
        color: #3D87C0;
        position: absolute;
        opacity: 0.9;
        margin-left: -20px;

        @media (max-width: @screen-xs-max) {
          font-size: 7em;
        }

        &.left {

          line-height: .7;
          @media (max-width: @screen-xs-max) {
            left: 5px;
          }
        }

        &.right {

          line-height: 0.95;
        }
      } // These values are the minimum and maximum 
      // viewport sizes to apply the font scaling
      @min_width_: 320;
      @max_width_: 767; // These values represent the range of font sizes to apply  
      // These values effect the base font-size, headings and 
      // other elements will scale proportionally
      @min_font_: 15;
      @max_font_: 26;




      h2 {
        font-family: @arco-type-medium;
        font-size: 2.25em;
        color: white;
        display: inline;
        line-height: 1.15;
        padding-left: 10px;
        letter-spacing: .5px;
        z-index: 2;
        position: inherit;

        @media (max-width: @screen-md-min) {
          font-size: 1.8em;
        }
        @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {
          font-size:~"calc( @{min_font_}px + (@{max_font_} - @{min_font_}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
        }
        @media (min-width: ~"@{max_width_}px") {
          //font-size: ~"@{max_font_}px;"
        }
        @media (max-width: @screen-xs-max) {
          //font-size: 1.1em;
          padding-left: 0px;
          letter-spacing: 0.25px;
        }
      }
    }
  }


  @min_width_: 320;

  @max_width_mob: 414;

  @max_width_: 767;

  @min_font_h1: 36;
  @max_font_h1: 42;

  .blue-div {
    border-top: 2px solid #1858AC;
    width: 90%;
    max-width: 960px;
    margin: 0 auto;
  }

  h1 {
    font-family: @arco-type-bold;
    font-size: 48px;
    padding-top: 50px;
    line-height: 1;

    @media (max-width: @screen-lg) {
      font-size: 36px;
      padding:20px 0 0 0;
      line-height: 1;
    }

    @media (max-width: @screen-md) {
      font-size: 32px;
      padding:20px 0 0 0;
      line-height: 1;
    }


    @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_mob}px") {

      font-size:~"calc( @{min_font_h1}px + (@{max_font_h1} - @{min_font_h1}) * ( (100vw - @{min_width_}px) / ( @{max_width_mob} - @{min_width_}) ));";
      margin-top: 0px;
      line-height: 38px;
    }

    @media (min-width: 415px) and (max-width: @screen-xs-max) {
      margin-top: 0px;
      line-height: 1;
      font-size: 38px;
    }
  }

  h2.goodwill-bold-heading {
    font-size: 42px;
    font-family: @arco-type-regular;
    margin-top: 15px;

    @media (max-width: @screen-lg) {
      font-size: 32px;
      margin-top: 0;
    }

    @media (max-width: @screen-md) {
      font-size: 28px;
    }

    @media (max-width: @screen-sm) {

      font-size: 26px;
    }
  }


  @min_font_h3: 11;
  @max_font_h3: 15;
  h3 {
    font-family: @arco-type-light;
    padding-top: 0px;
    padding-bottom: 5px;
    line-height: 1.2;
    margin: 0 auto;
    font-size: 21px; //width: 95%; // opacity: .9;
    text-align: left;
    color: #fff;

    .goodwill-hash {
      font-family: @arco-type-bold;
    }

    @media (max-width: @screen-lg-min) {
      //font-size: 18px;
      padding-top: 10px;
      line-height: 1.2;
      font-size: 18px;
    }

    @media (max-width: @screen-md-min) {
      //text-align: left;
      margin-left: 0;
      line-height: 1.2;
      padding-bottom: 25px;
      font-size: 16px;
    }

    @media (max-width: @screen-sm-min) {
      //text-align: left;
      margin-left: 0;
      font-size: 16px;
    }

    @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_mob}px") {

      font-size:~"calc( @{min_font_h3}px + (@{max_font_h3} - @{min_font_h3}) * ( (100vw - @{min_width_}px) / ( @{max_width_mob} - @{min_width_}) ));"
    }



    @media (max-width: @screen-xs-max) {
      padding-top: 20px;
      padding-bottom: 15px; //width: 100%;
      text-align: left;
      letter-spacing: 0.2px; //font-size: 9.5px;
    }
  } //@min_width_: 400;
  //@max_width_: 767;
  @min_font_motto: 8;
  @max_font_motto: 16;


  h4 {
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 35px; // font-size: 24px;
    font-size: 14px; // opacity: .9;
    font-family: @arco-type-medium;
    text-align: left;
    /*color: #848484;*/

    @media (max-width: @screen-md-min) {
      /*text-align: left;*/
      margin-left: 0;
    }
    @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {

      font-size:~"calc( @{min_font_motto}px + (@{max_font_motto} - @{min_font_motto}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
    }
    @media (min-width: ~"@{max_width_}px") {

      //font-size: ~"@{max_font_motto}px;"
    }
    @media (max-width: @screen-xs-max) {
      padding-top: 10px;
      padding-bottom: 10px; //font-size: 8px;
      
    }
  }

  @min_logo_width: 145;
  @max_logo_width: 300;

  img {
    width: 270px;
    padding-top: 21px;
    //margin: 0 auto;

    @media (min-width: @screen-md-min) {
      //padding-top: 10px;
      //margin-left: 0;
      padding-top: 35px;
    }

    @media (max-width: @screen-sm-min) {}

    @media (min-width: ~"@{min_width_}px") and (max-width: ~"@{max_width_}px") {

      width:~"calc( @{min_logo_width}px + (@{max_logo_width} - @{min_logo_width}) * ( (100vw - @{min_width_}px) / ( @{max_width_} - @{min_width_}) ));"
    }

    @media (max-width: @screen-xs-max) {
      //width: 145px;
      padding-top: 30px;
    }
  }

  #donation-button {
    display: block;
    width: 120px;
    padding: 2px 0px;
    padding-top: 5px;
    color: #0d63ae;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    margin: 0 auto;
    line-height: 1.15;
    border-radius: 10px;
  }
}


//END -- SOCAL GOODWILL PROMO STYLING -- END 
.socal-goodwill-promo.ng-hide-add {
  //animation: fadeOut 1s;
}

.socal-goodwill-promo.ng-hide-remove {
  //animation: fadeIn 2s;
  // animation: slideInDown 2s;
}

.mobile-wallet-promo,
.video-module {
  background: #0092ff;
  background: -moz-linear-gradient(top, #0092ff 0%, #0061b1 100%);
  background: -webkit-linear-gradient(top, #0092ff 0%, #0061b1 100%);
  background: linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
}




//Had to isolate the slight white to off white gradient for ARCO man because the class it was using to implement that (video module) now has the blue gradient used on mobile wallet. 
//Fleetcor promo uses this offwhite gradient as well
#arcoman-module,
.fleetcor-promo {
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #e1e1e1 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #e1e1e1 100%);
}

.social-module {
  display: none;

  background: #0092ff;
  background: -moz-linear-gradient(top, #0092ff 0%, #0061b1 100%);
  background: -webkit-linear-gradient(top, #0092ff 0%, #0061b1 100%);
  background: linear-gradient(to bottom, #0092ff 0%, #0061b1 100%);
  h3 {
    color: #fff;
  } // Speed up the carousel animation
 span {
    font-size: 19px;
    color: white;
 }
  .carousel-inner {
    >.item {
      @media all and (transform-3d),
      (-webkit-transform-3d) {
        .transition-transform(~'0.2s ease-in-out');
      }
    }
  } // Modify the position of the chevrons for the carousel
  .carousel-control {
    display: none;
    .glyphicon-chevron-left {
      left: -8%;
      margin-left: -10px;
    }
    .glyphicon-chevron-right {
      right: -8%;
      margin-right: -10px;
    }
  }
  .carousel-indicators {
    bottom: -15px;
    li,
    .active {
      margin: 4px;
    }
    li {
      background-color: rgba(255, 255, 255, .5);
      &.active {
        background-color: #fff;
      }
    }
  }
}

.m-home {
  a:not(.btn) {
    color: inherit;
    text-decoration: underline;
    &:hover {
      color: @brand-arco-red;
    }
  }
  .carousel-inner a {
    &:hover {
      color: @arco-darkcopy;
    }
  }
  .desktop-social a {
    &:hover {
      color: @arco-darkcopy;
    }
  }
  .jumbotron-asterisk {
    font-family: @arco-type-thin;
    position: relative;
    left: -0.25em;
  }
}

.social-feed-item {
  -webkit-box-shadow: 0px 13px 12px -10px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 0px 13px 12px -10px rgba(0, 0, 0, 0.65);
  box-shadow: 0px 13px 12px -10px rgba(0, 0, 0, 0.65);
  padding: 18px; // #c0c0c0
  background: #c0c0c0;
  background: -moz-linear-gradient(top, #c0c0c0 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #c0c0c0 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #c0c0c0 0%, #ffffff 100%);
  position: relative;
  cursor: pointer;
  .social-feed-pic {
    box-sizing: border-box; // border: 10px solid #fff;
    overflow: hidden;
  }
  .social-feed-copy {
    padding: 15px 0px;
    height: 120px;
    position: absolute;
    bottom: 0;
    padding-right: 15px;
    p {
      overflow: hidden;
      max-height: 75px;
    }
  }
  .social-feed-bottom {
    bottom: 15px;
    right: 15px;
    position: absolute; // img:nth-child(1) {
    //     position: absolute;
    //     bottom: 15px;
    //     left: 15px;
    // }
    // img:nth-child(2),
    .fb-like-post {
      width: 58px;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}



// Main Pano Module
@media(min-width: @screen-sm) {
  .m-home {
    .arco-jumbotron {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%
    }
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
      .main-pano {
        // img {
        //     width: 100%;
        // }
      }
      .main-pano {
        height: 520px;
      }
      .main-pano.sunrise {
        background: url('../../../../img/home-jumbotron-sunrise.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.noon {
        background: url('../../../../img/home-jumbotron-noon-new.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.night {
        background: url('../../../../img/home-jumbotron-night.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano-copy {
        p {
          //font-size: 35px;
        }
      }
      .main-pano-copy {
        position: absolute;
        top: 45px;
        left: 0;
        right: 0;
        margin: 0 auto;
        p {
          padding: 10px;
          font-size: 30px;
          font-family: @arco-type-light;
          color: #fff;
          text-shadow: -1px 2px 2px rgba(28, 28, 28, 0.9);
        }
      }
    }
  }
}

@media (min-width: @screen-md) {
  .m-home {
    .container-fluid {
      .main-pano {
        height: 555px;
      }
      .main-pano.sunrise {
        background: url('../../../../img/home-jumbotron-sunrise.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.noon {
        background: url('../../../../img/home-jumbotron-noon-new.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.night {
        background: url('../../../../img/home-jumbotron-night.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano-copy {
        p {
          font-size: 32px;
        }
      }
    }
  }
}

@media (min-width: @screen-lg) {
  .m-home {
    .container-fluid {
      .main-pano {
        min-height: 665px;
      }
      .main-pano.sunrise {
        background: url('../../../../img/home-jumbotron-sunrise.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.noon {
        background: url('../../../../img/home-jumbotron-noon-new.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.night {
        background: url('../../../../img/home-jumbotron-night.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano-copy {
        p {
          font-size: 42px;
          line-height: 1.35;
        }
      }
    }
  }
}

@media(max-width: @screen-xs-max) {
  .m-home {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;

      .main-pano {

        margin: 0 auto;
        width: 100%;
        min-height: 360px; //background: url('../../../../img/home-jumbotron-night.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.sunrise {
        background: url('../../../../img/home-jumbotron-sunrise-new.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.noon {
        background: url('../../../../img/home-jumbotron-noon-new.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }
      .main-pano.night {
        background: url('../../../../img/home-jumbotron-night-new.jpg') no-repeat 56% 50%; // THis will be changed to be set via JS
        background-size: cover;
      }

      .main-pano-copy {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        p {
          padding-top: 10px;
          font-size: 22px;
          font-family: @arco-type-light;
          color: #fff;
          text-shadow: -1px 2px 2px rgba(28, 28, 28, 0.9);
          line-height: 1.25;
        }
      }
    }
  }
}

@media(max-width: (@screen-xs - 1)) {
  .m-home {
    .container-fluid {
      .main-pano {
        //min-height: 320px;
      }
    }
  }
}



//region selector modal
@media(max-width: @screen-xs-max) {


    .modal-dialog {
        margin: 30px;
    }

    .modal {
        .modal-logo img {
            padding:30px 0 0 0;
        }

        .selectormap img {
            width:100%;
        }

        .modal-select-right {
            padding:40px 0 0 0;
        }

        .modal-select-region-title {
            font-size:16px;
        }

        .regionselector {
            color: #333;
            background: #fff;
            background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e1e1e1));
            background: -webkit-linear-gradient(top,#fff 0,#e1e1e1 100%);
            background: -o-linear-gradient(top,#fff 0,#e1e1e1 100%);
            background: linear-gradient(to bottom,#fff 0,#e1e1e1 100%);
            padding:0 0 40px 0;
        }

        .btn-regionselect {
            color:#fff;
            background: #008ff9;
            background: url('/img/region-select-icon.png'), -moz-linear-gradient(top, #008ff9 0%, #0078d6 53%, #0062b2 100%);
            background: url('/img/region-select-icon.png'), -webkit-linear-gradient(top, #008ff9 0%,#0078d6 53%,#0062b2 100%);
            background: url('/img/region-select-icon.png'), linear-gradient(to bottom, #008ff9 0%,#0078d6 53%,#0062b2 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008ff9', endColorstr='#0062b2',GradientType=0 );
            background-repeat:no-repeat;
            background-size:20px, auto;
            background-position:95% center;
        }

        .btn-regionselect:hover {
            background: #0087ef;
            background: url('/img/region-select-icon.png'), -moz-linear-gradient(top, #0087ef 0%, #0067ce 53%, #00467f 100%);
            background: url('/img/region-select-icon.png'), -webkit-linear-gradient(top, #0087ef 0%,#0067ce 53%,#00467f 100%);
            background: url('/img/region-select-icon.png'), linear-gradient(to bottom, #0087ef 0%,#0067ce 53%,#00467f 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0087ef', endColorstr='#00467f',GradientType=0 );
            background-repeat:no-repeat;
            background-size:20px, auto;
            background-position:95% center;
        }

        .btn-selector {
            font-size:14px;
            font-weight:700;
            padding:20px 0 20px 20px;
        }

        .modal-select-region-links a:link, .modal-select-region-links a:visited, .modal-select-region-links a:active, .modal-select-region-links a:hover {
            text-decoration:none !important;
            color:#fff !important;

        }
    }
}

@media(min-width: @screen-sm) {
    .modal-dialog {
        margin-top: 80px;
    }
    .modal {
        .modal-logo img {
            padding:30px 0 0 0;
        }

        .selectormap img {
            width:100%;
        }

        .modal-select-right {
            padding:30px 0 0 0;
            text-align:left;
        }

        .modal-select-region-title {
            font-size:16px;
        }

        .regionselector {
            color: #333;
            background: #fff;
            background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e1e1e1));
            background: -webkit-linear-gradient(top,#fff 0,#e1e1e1 100%);
            background: -o-linear-gradient(top,#fff 0,#e1e1e1 100%);
            background: linear-gradient(to bottom,#fff 0,#e1e1e1 100%);
            padding:0 0 40px 0;
        }

        .btn-regionselect {
            color:#fff;
            background: #008ff9;
            background: url('/img/region-select-icon.png'), -moz-linear-gradient(top, #008ff9 0%, #0078d6 53%, #0062b2 100%);
            background: url('/img/region-select-icon.png'), -webkit-linear-gradient(top, #008ff9 0%,#0078d6 53%,#0062b2 100%);
            background: url('/img/region-select-icon.png'), linear-gradient(to bottom, #008ff9 0%,#0078d6 53%,#0062b2 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008ff9', endColorstr='#0062b2',GradientType=0 );
            background-repeat:no-repeat;
            background-size:20px, auto;
            background-position:95% center;
        }

        .btn-regionselect:hover {
            background: #0087ef;
            background: url('/img/region-select-icon.png'), -moz-linear-gradient(top, #0087ef 0%, #0067ce 53%, #00467f 100%);
            background: url('/img/region-select-icon.png'), -webkit-linear-gradient(top, #0087ef 0%,#0067ce 53%,#00467f 100%);
            background: url('/img/region-select-icon.png'), linear-gradient(to bottom, #0087ef 0%,#0067ce 53%,#00467f 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0087ef', endColorstr='#00467f',GradientType=0 );
            background-repeat:no-repeat;
            background-size:20px, auto;
            background-position:95% center;
        }

        .btn-selector {
            font-size:14px;
            font-weight:700;
            padding:20px 0 20px 20px;
        }

        .modal-select-region-links a:link, .modal-select-region-links a:visited, .modal-select-region-links a:active, .modal-select-region-links a:hover {
            text-decoration:none !important;
            color:#fff !important;

        }
    }
}

@media (min-width: @screen-md) {
    .modal-dialog {
        margin-top: 100px;
    }

    .modal {
        .modal-logo img {
            padding:30px 0 0 0;
        }

        .selectormap img {
            max-height:400px;
        }

        .modal-select-right {
            padding:40px 0 0 0;
            text-align:left;
        }

        .modal-select-region-title {
            font-size:20px;
        }

        .regionselector {
            color: #333;
            background: #fff;
            background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e1e1e1));
            background: -webkit-linear-gradient(top,#fff 0,#e1e1e1 100%);
            background: -o-linear-gradient(top,#fff 0,#e1e1e1 100%);
            background: linear-gradient(to bottom,#fff 0,#e1e1e1 100%);
            padding:0 0 40px 0;
        }

        .btn-regionselect {
            color:#fff;
            background: #008ff9;
            background: url('/img/region-select-icon.png'), -moz-linear-gradient(top, #008ff9 0%, #0078d6 53%, #0062b2 100%);
            background: url('/img/region-select-icon.png'), -webkit-linear-gradient(top, #008ff9 0%,#0078d6 53%,#0062b2 100%);
            background: url('/img/region-select-icon.png'), linear-gradient(to bottom, #008ff9 0%,#0078d6 53%,#0062b2 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008ff9', endColorstr='#0062b2',GradientType=0 );
            background-repeat:no-repeat;
            background-size:30px, auto;
            background-position:95% center;
        }

        .btn-regionselect:hover {
            background: #0087ef;
            background: url('/img/region-select-icon.png'), -moz-linear-gradient(top, #0087ef 0%, #0067ce 53%, #00467f 100%);
            background: url('/img/region-select-icon.png'), -webkit-linear-gradient(top, #0087ef 0%,#0067ce 53%,#00467f 100%);
            background: url('/img/region-select-icon.png'), linear-gradient(to bottom, #0087ef 0%,#0067ce 53%,#00467f 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0087ef', endColorstr='#00467f',GradientType=0 );
            background-repeat:no-repeat;
            background-size:30px, auto;
            background-position:95% center;
        }

        .btn-selector {
            font-size:18px;
            font-weight:700;
            padding:30px 0 30px 30px;
        }

        .modal-select-region-links a:link, .modal-select-region-links a:visited, .modal-select-region-links a:active, .modal-select-region-links a:hover {
            text-decoration:none !important;
            color:#fff !important;

        }
    }
}

@media (min-width: @screen-lg) {
    .modal-dialog {
        margin-top: 120px;
    }

    .modal {
        .modal-logo img {
            padding:30px 0 0 0;
        }

        .selectormap img {
            max-height:400px;
        }

        .modal-select-right {
            padding:40px 0 0 0;
            text-align:left;
        }

        .modal-select-region-title {
            font-size:20px;
        }

        .regionselector {
            color: #333;
            background: #fff;
            background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#e1e1e1));
            background: -webkit-linear-gradient(top,#fff 0,#e1e1e1 100%);
            background: -o-linear-gradient(top,#fff 0,#e1e1e1 100%);
            background: linear-gradient(to bottom,#fff 0,#e1e1e1 100%);
            padding:0 0 40px 0;
        }

        .btn-regionselect {
            color:#fff;
            background: #008ff9;
            background: url('/img/region-select-icon.png'), -moz-linear-gradient(top, #008ff9 0%, #0078d6 53%, #0062b2 100%);
            background: url('/img/region-select-icon.png'), -webkit-linear-gradient(top, #008ff9 0%,#0078d6 53%,#0062b2 100%);
            background: url('/img/region-select-icon.png'), linear-gradient(to bottom, #008ff9 0%,#0078d6 53%,#0062b2 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008ff9', endColorstr='#0062b2',GradientType=0 );
            background-repeat:no-repeat;
            background-size:30px, auto;
            background-position:95% center;
        }

        .btn-regionselect:hover {
            background: #0087ef;
            background: url('/img/region-select-icon.png'), -moz-linear-gradient(top, #0087ef 0%, #0067ce 53%, #00467f 100%);
            background: url('/img/region-select-icon.png'), -webkit-linear-gradient(top, #0087ef 0%,#0067ce 53%,#00467f 100%);
            background: url('/img/region-select-icon.png'), linear-gradient(to bottom, #0087ef 0%,#0067ce 53%,#00467f 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0087ef', endColorstr='#00467f',GradientType=0 );
            background-repeat:no-repeat;
            background-size:30px, auto;
            background-position:95% center;
        }

        .btn-selector {
            font-size:18px;
            font-weight:700;
            padding:30px 0 30px 30px;
        }

        .modal-select-region-links a:link, .modal-select-region-links a:visited, .modal-select-region-links a:active, .modal-select-region-links a:hover {
            text-decoration:none !important;
            color:#fff !important;

        }
    }

}




// MOBILE FIRST APPROACH, MODIFY AS I GO UP WHEN NEEDED TO RESIZE FOR THE LARGER DEVICE
// Begin Module Styling
// Max Width = 767 mobile devices
@media(max-width: @screen-xs-max) {

  #arcoman-module {
    h3 {
      color: #333;
      font-size: 22px;
      font-family: @arco-type-regular;
    }
  }

  .fleetcor-promo {
    padding: 15px 0px 40px 0px;
    position: relative;
    h3 {
      font-size: 22px;
      font-family: @arco-type-regular;
    }

    .fleet-bold {
      font-family: @arco-type-bold;
    }
    img.cards {
      padding-bottom: 30px;
      padding-top: 20px;
      width: 100%;
      max-width: 320px;
    }
    img.blue-ribbon {
     max-width: 95px;
     position: absolute;
     top: 0;

    }
    .btn {
      max-width: 128px;
      margin-top: 15px;
    }
  }

  .vid-share-btns {
    position: absolute;
    right: 40px;
    top: 5px;
  }
  .mobile-wallet-promo {
    color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    .col-xs-6:nth-child(2) {
      padding-left: 0;
      h3 {
        font-family: 'DIN Next W01 Regular';
        font-size: 21px;
      }
      p {
        font-family: 'DIN Next W01 Light';
        font-size: 18px;
      }
    }
  }
  .video-module {
    padding-top: 15px;
    padding-bottom: 45px;
    h3 {
      font-family: 'DIN Next W01 Light';
      font-weight: 600;
      font-size: 18px;
      color: white;
    }
    span {
      font-size: 18px;
      color: white;
    }
    .video-module-top {
      position: relative;
      margin-top: 25px;
      border-bottom: 1px solid white;
      >span {
        font-family: 'DIN Next W01 Regular';
        font-size: 23px;
      }
      >img {
        position: absolute;
        top: 0;
        right: 0;
        height: 32px;
      }
    }
    .video-module-copy {
      margin-top: 10px;
      font-family: 'DIN Next W01 Regular';
      font-size: 16px;
    }
    .embed-responsive {
      margin-top: 10px;
      box-shadow: 0 4px 7px -1px rgba(28, 28, 28, 0.5);
      margin-bottom: 20px;
    }
  }


 .video-module {
    /* when hiding the picture */
    /*.vid-share-btns.ng-hide-add {
      animation: 150ms slideOutLeft ease;
    }*/
    /* when showing the picture */
    /*.vid-share-btns.ng-hide-remove {
      animation: 200ms slideInLeft ease;
    }*/
    padding-top: 45px;
    padding-bottom: 45px;
    .col-xs-12 {
      >h3 {
        margin-bottom: 20px;
        color: white;
      }
      >span {
      font-size: 18px;
      color: white;
      display:inline-block;
      margin-bottom:15px;
      }
    }
    .video-feature-module {
      span {
        font-size: 17px;
        display: block;
        padding-top: 5px;
        padding-bottom: 30px;
        color: white;
      }
      .embed-responsive {
        margin-bottom: 30px;
      }
    }
    .video-module-bottom {
      div:nth-child(1) {
        font-family: 'DIN Next W01 Medium';
        padding-top: 4px;
        font-size: 19px;
      }
      div:nth-child(2) {
        height: 64px;
        font-size: 15px;
        padding-bottom: 4px;
      }
    }
    .video-module-bottom-share {
      cursor: pointer;
      overflow-y: hidden;
      overflow-x: hidden;
      height: 34px;
      margin-bottom: 15px;
      position: relative; // border-top: 2px solid @brand-arco-red;
      border-top: 2px solid white;
      >span {
        font-family: 'DIN Next W01 Regular';
        font-size: 23px;
      }
      >img {
        position: absolute;
        top: 0;
        left: 0;
        height: 32px; // cursor: pointer;
      }
    }
    .embed-responsive {
      box-shadow: 0 4px 7px -1px rgba(28, 28, 28, 0.5);
    }
  }
  .vid-share-link {
    //color: @brand-arco-red;
    color: white;
    position: absolute;
    left: 45px;
    top: 8px;
    cursor: default;
  }
  .vid-share-btns {
    position: absolute;
    left: 45px;
    top: 7px;
    color: @brand-arco-red;
    z-index: 10;
    >iframe:nth-child(1) {
      position: relative;
      top: -10px;
    }
    >iframe:nth-child(2) {
      position: relative;
      top: 0px;
    }
    >a {
      position: relative;
      top: -10px;
    }
  }
  .social-module {
    .carousel-control.left,
    .carousel-control.right {
      background-image: none;
    }
    padding-top: 15px;
    padding-bottom: 25px;
    h3 {
      font-family: 'DIN Next W01 Light';
      color: #fff;
    }
    .social-feed-item {
      height: inherit;
      margin-bottom: 45px;
      min-height: 335px;
      .social-feed-pic-mobile {
        width: 100%;
        min-height: 200px;
        max-height: 390px;
        overflow: hidden;
        img {
          margin: 0 auto;
        }
      }
      .social-feed-copy {
        position: relative;
        max-height: 100px;
        padding: 5px 0px;
      }
      .social-feed-bottom {
        right: 5px;
        bottom: 0px;
      }
    }
  }


  .m-home {
    .social-module {
      a {
        text-decoration: none;
      }
    }
  }
}

// If i want to do things to the very small mobile view port, do that here
@media(max-width: (@screen-xs - 1)) {
  .mobile-wallet-promo {
    .col-xs-6:nth-child(2) {
      p {
        font-size: 14px;
      }
    }
  }
}

// Min Width = 768px (Tablets)
@media(min-width: @screen-sm) {

  #arcoman-module {
    h3 {
      color: #333;
      font-size: 29px;
      font-family: @arco-type-regular;
      margin-bottom: 10px;
    }
  }

  .share-image,
  .share-cancel {
    cursor: pointer;
  }
  .share-image {
    z-index: 20;
  }
  .share-cancel {
    display: none;
  }
  .share-cancel {
    position: relative;
    top: -6px;
    left: 8px;
  }
  .vid-share-link {
    //color: @brand-arco-red;
    color: white;
    position: absolute;
    left: 45px;
    top: 8px;
    cursor: default;
  }
  .vid-share-btns {
    position: absolute;
    left: 45px;
    top: 7px;
    color: @brand-arco-red;
    z-index: 10;
    >iframe:nth-child(1) {
      position: relative;
      top: -10px;
    }
    >iframe:nth-child(2) {
      position: relative;
      top: 0px;
    }
    >a {
      position: relative;
      top: -10px;
    }
  }

  .fleetcor-promo {
    .fleet-bold {
      font-family: @arco-type-bold;
    }
    padding: 50px 0px;
    h3 {
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      line-height: 1.25; //letter-spacing: 1px;
      margin-bottom: 25px;
    }
    .blue-ribbon {
     margin-top: -50px;
     max-width: 100px;
    }
  }

  .mobile-wallet-promo {
    .container {
      // margin-bottom: -20px;
    }
    .col-xs-6:nth-child(1) {
      padding-right: 0;
    }
    .col-xs-6:nth-child(2) {
      padding-left: 0;
      color: #fff;
      h3 {
        font-family: 'DIN Next W01 Light';
        font-size: 29px;
        margin-top: 136px;
        margin-bottom: 22px;
      }
      p {
        font-family: 'DIN Next W01 Regular';
        font-size: 15px;
      }
      .btn {
        margin-top: 9px;
        box-shadow: 0 4px 7px -1px rgba(28, 28, 28, 0.8);
      }
    }
  }
  .social-module {
    padding-top: 45px;
    padding-bottom: 45px;
    .col-xs-12 {
      >h3 {
        margin-bottom: 25px;
      }
    }
    .social-feed-item {
      margin-bottom: 45px;
      height: auto;
      .social-feed-pic {
        /*max-height: 105px;*/
      }
      .social-feed-bottom {
        position: absolute;
        right: 5px;
        bottom: 0px;
      }
      .social-feed-copy {
        position: relative;
      }
    }
  }
  .video-module {
    /* when hiding the picture */
    .vid-share-btns.ng-hide-add {
      animation: 150ms slideOutLeft ease;
    }
    /* when showing the picture */
    .vid-share-btns.ng-hide-remove {
      animation: 200ms slideInLeft ease;
    }
    padding-top: 45px;
    padding-bottom: 45px;
    .col-xs-12 {
      >h3 {
        margin-bottom: 20px;
        color: white;
      }
      >span {
      font-size: 18px;
      color: white;
      display:inline-block;
      margin-bottom:15px;
      }
    }
    .video-feature-module {
      span {
        font-size: 17px;
        display: block;
        padding-top: 5px;
        padding-bottom: 30px;
        color: white;
      }
      .embed-responsive {
        margin-bottom: 30px;
      }
    }
    .video-module-bottom {
      div:nth-child(1) {
        font-family: 'DIN Next W01 Medium';
        padding-top: 4px;
        font-size: 19px;
      }
      div:nth-child(2) {
        height: 64px;
        font-size: 15px;
        padding-bottom: 4px;
      }
    }
    .video-module-bottom-share {
      cursor: pointer;
      overflow-y: hidden;
      overflow-x: hidden;
      height: 34px;
      margin-bottom: 15px;
      position: relative; // border-top: 2px solid @brand-arco-red;
      border-top: 2px solid white;
      >span {
        font-family: 'DIN Next W01 Regular';
        font-size: 23px;
      }
      >img {
        position: absolute;
        top: 0;
        left: 0;
        height: 32px; // cursor: pointer;
      }
    }
    .embed-responsive {
      box-shadow: 0 4px 7px -1px rgba(28, 28, 28, 0.5);
    }
  }
}

// Min Width = 992px (Medium Desktop)
@media (min-width: @screen-md) {

  #arcoman-module {
    h3 {
      //color: #333;
      font-size: 35px;
    }
  }


  .share-cancel {
    display: initial;
  }

  .fleetcor-promo {
    padding: 60px 0px;
    h3 {
      font-size: 32px;
    }
    p {
      font-size: 20px;
      margin-bottom: 35px;
    }
    .blue-ribbon {
     margin-top: -60px;
    }
  }

  .mobile-wallet-promo {
    .col-xs-6:nth-child(2) {
      h3 {

        font-size: 35px;
        margin-top: 87px;
        margin-bottom: 22px;
      }
      p {
        font-size: 18px;
      }
      .btn {
        margin-top: 28px;
      }
    }
  }
  .social-module {
    .col-xs-12 {
      >h3 {
        margin-bottom: 40px;
      }
    }
    h3 {
      font-size: 35px;
    }
    .social-feed-item {
      height: auto;
      .social-feed-pic {
        /*max-height: 145px;*/
      }
      .social-feed-copy {
        position: relative;
        bottom: 10px;
      }
    }
  }
  .video-module {
    .col-xs-12 {
      >h3 {
        margin-bottom: 15px;
      }
       >span {
          font-size: 19px;
          color: white;
          display:inline-block;
          margin-bottom: 15px;
        }
    }
    .video-module-bottom {
      div:nth-child(2) {
        height: 44px;
      }
    }
    h3 {
      font-size: 32px;
      font-weight: 600;
    }
    .video-feature-module {
      span {
        font-size: 19px;
        color: white;
      }
      .embed-responsive {
        margin-bottom: 30px;
      }
    }
  }
}

// Min width = 1200px (Large Desktop)
@media (min-width: @screen-lg) {

  #arcoman-module {
    h3 {
      //color: #333;
      //font-size: 35px;
    }
  }

  .fleetcor-promo {

    padding: 65px 0px;
    h3 {
      font-size: 35px;
    }
    p {
      font-size: 21px;
      margin-bottom: 45px;
    }
    .blue-ribbon {
     margin-top: -65px;
    }
  }
  .mobile-wallet-promo {
    padding-top: 10px;
    .col-xs-6:nth-child(2) {
      h3 {
        font-size: 35px;
        margin-top: 87px;
        margin-bottom: 22px;
      }
      p {
        font-size: 18px;
      }
      .btn {
        margin-top: 41px;
      }
    }
  }
  .social-module {
    .social-feed-item {
      height: auto;
      .social-feed-pic {
        /*max-height: 185px;*/
      }
      .social-feed-copy {
        position: relative;
        bottom: 0px;
      }
    }
  }
  .video-module {
    h3 {
      font-size: 2.25em;
    }
    .video-feature-module {
      span {
        font-size: 19px;
        color: white;
      }
      .embed-responsive {
        margin-bottom: 30px;
      }
    }
  }
}